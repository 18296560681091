<common-top-tool-bar [title]="titleName+actionName"
                     [back]="backUrl"
                     [actionsTemplate]="actions">
  <ng-template #actions>
    <button nz-button [nzType]="'primary'" class="mr-4"
            [nzSize]="'large'" nzGhost
            [routerLink]="backUrl">
      取消
    </button>
    <button nz-button [nzType]="'primary'"
            [nzSize]="'large'"
            [disabled]="form.invalid || tabIndex==1"
            (click)="formElement.ngSubmit.emit()">
      {{this.item_id ? '保存' : '确定新增' }}
    </button>
  </ng-template>
</common-top-tool-bar>






<div class="page-tab-main v3">
  <nz-spin [nzSpinning]="isLoading">
  <nz-tabset nzLinkRouter nzType="card" (nzSelectedIndexChange)="tabIndexChange($event)" [nzSelectedIndex]="tabIndex">
    <nz-tab>
      <a *nzTabLink nz-tab-link [routerLink]="['.']" [queryParams]="{ tabIndex: '0' }" queryParamsHandling="merge">
        基本信息
      </a>
      <div class="page-main">
          <div class="page-main-wrapper" style="padding: 0 2rem;">
            <form nz-form #formElement="ngForm" [formGroup]="form" (ngSubmit)="submitForm($event, form.value )">
            <ng-template #customFileList let-fileList>
              <div class="custom-file-list ">
                <ng-container formArrayName="itemsArray"
                              *ngFor="let file of fileList; let i = index;"
                               >
                <div class="custom-file-item py-4 flex flex-row">
                  <div class="flex flex-col flex-1 p-4">
                    <div class="flex flex-1">
                      <!-- 文件类型图标 -->
                      <!--                  <span class="file-icon">-->
                      <!--                    <ng-container [ngSwitch]="getFileType(file)">-->
                      <!--                      <span *ngSwitchCase="'image'" nz-icon nzType="file-image"></span>-->
                      <!--                      <span *ngSwitchCase="'pdf'" nz-icon nzType="file-pdf"></span>-->
                      <!--                      <span *ngSwitchCase="'excel'" nz-icon nzType="file-excel"></span>-->
                      <!--                      <span *ngSwitchDefault nz-icon nzType="file"></span>-->
                      <!--                    </ng-container>-->
                      <!--                  </span>-->


                      <!-- 文件名 -->

                      <span class="file-name flex-1 leading-8">
                        {{ file.name }}
                      </span>

<!--                      <span class="file-name leading-8">[{{ convertTimeToTag(file?.response?.duration) }}]</span>-->
<!--                      <span class="file-name leading-8">[{{ convertTimeToTag(item?.controls['duration'].value) }}]</span>-->

                      <!-- 文件状态 -->
                      <span class="file-status leading-8" *ngIf="file.status !== 'uploading'">
                        <ng-container [ngSwitch]="file.status">
                          <span *ngSwitchCase="'done'" class="text-success">
                            <span nz-icon nzType="check-circle"></span> 已上传
                          </span>
                          <span *ngSwitchCase="'error'" class="text-danger">
                            <span nz-icon nzType="close-circle"></span> 上传失败
                          </span>
                          <span *ngSwitchDefault>{{ file.status }}</span>
                        </ng-container>
                      </span>
                      <!-- 预览按钮 (仅对图片或PDF) -->
<!--                      <button *ngIf="file.status === 'done' && isPreviewable(file)" nz-button nzType="text" (click)="previewFile(file)">-->
<!--                        <span nz-icon nzType="eye"></span>-->
<!--                      </button>-->

                      <!-- 下载按钮 -->
                      <a *ngIf="file?.response?.url && file.status === 'done'" [href]="file?.response?.url" target="_blank" download>
                        <button nz-button nzType="text">
                          <span nz-icon nzType="download"></span>
                        </button>
                      </a>

                      <!-- 删除按钮 -->
                      <button *ngIf="file?.response?.url && file.status === 'done'" nz-button nzType="text" (click)="removeFile(file)">
                        <span nz-icon nzType="delete"></span>
                      </button>
                    </div>
                    <div>
<!--                       上传进度显示-->
                      <div *ngIf="file.status === 'uploading'" class="file-progress">
                        <nz-progress [nzPercent]="Math.round(file.percent * 100)" [nzShowInfo]="true" nzSize="small"></nz-progress>
                        <!--                      <span class="progress-text">{{(file.percent * 100).toFixed(2) }}%</span>-->
                      </div>
                    </div>
                  </div>
                </div>
                </ng-container>
              </div>
            </ng-template>
            <nz-upload
              [nzAccept]="bisType == 'audio' ? '.mp3' : '.mp4'"
              [nzBeforeUpload]="beforeBatchUpload"
              [nzCustomRequest]="customUploadRequest"
              nzType="drag"
              [nzMultiple]="true"
              [(nzFileList)]="batchUploadFileList"
              [nzFileListRender]="customFileList"
              (nzChange)="handleChange($event)"
            >

              <p class="ant-upload-drag-icon">
                <span nz-icon nzType="inbox"></span>
              </p>
              <p class="ant-upload-text">点击或者拖拽文件到此区域</p>
              <p class="ant-upload-hint">
                支持单个或多个文件上传
              </p>
            </nz-upload>
              <div>
                <ng-container formArrayName="itemsArray"
                              *ngFor="let item of itemsArray.controls; let i = index;"
                >
                  <!--                  *ngFor="let file of fileList; let i = index;"-->
                  <div [formGroupName]="i" class="custom-file-item py-4 flex flex-row">
                    <div style="width: 250px; padding: 4px">
                      <!--                    <div *ngIf="!file?.response?.url" class="w-full h-full">-->
                      <div *ngIf="!item?.controls['url'].value" class="w-full h-full">
                        <div class="w-full h-full flex justify-center items-center">
                          <nz-progress [nzPercent]="Math.round(file.percent * 100)" nzType="circle" [nzWidth]="80"></nz-progress>
                        </div>
                      </div>
                      <!--                    <div *ngIf="file?.response?.url" style="padding: 4px">-->
                      <div *ngIf="item?.controls['url'].value" style="padding: 4px">
                        <app-upload-image-with-preview-v2
                          [picPreview]="true"
                          [innerClass]=""
                          style="width: 100%;display: inline-block;"
                          [displayTextTemplate]="displayTextTemplate"
                          [displayIcon]="null"
                          [picUrl]="item?.controls['cover'].value"
                          (imageUploaded)="setCover($event, i)"
                          previewClass="bg-cover bg-center"
                          [displayRatioWidth]="0"
                          [displayRatioHeight]="0">
                          <ng-template #displayTextTemplate>
                            <div class="p-6">请将文件拖曳至此或点击此处上传图片。</div>
                          </ng-template>
                        </app-upload-image-with-preview-v2>
                        <div class="font-light text-xs px-4">注：图片格式为 Jpg 或 Png 文件，图片文件大小限制为 2MB</div>
                      </div>
                    </div>
                    <div *ngIf="bisType == 'video'" style="width: 250px">
                      <div *ngIf="item?.controls['url'].value">
                        <tw-video-uploader
                          [videoUrl]="(item?.controls['url'].value | mediaUrlPipe )"
                          [limit]="300"
                          [allowUpload]="false"
                          comment="注：视频格式为 Mp4 文件，音频文件大小限制为 300MB。"
                          (videoCoverUploaded)="onVideoCoverUploadSuccess($event, i)">
                        </tw-video-uploader>
                      </div>
                    </div>
                    <div *ngIf="bisType == 'audio'" style="width: 250px">
                      <div class="h-full flex justify-center items-center">
                        <!--                      {{item.controls.url.value}}-->
                        <audio class="w-full" [src]="item.controls.url.value" controls></audio>
                      </div>
                    </div>
                    <div class="flex flex-col flex-1 p-4">
                      <div class="flex flex-1">
                        <!-- 文件类型图标 -->
                        <!--                  <span class="file-icon">-->
                        <!--                    <ng-container [ngSwitch]="getFileType(file)">-->
                        <!--                      <span *ngSwitchCase="'image'" nz-icon nzType="file-image"></span>-->
                        <!--                      <span *ngSwitchCase="'pdf'" nz-icon nzType="file-pdf"></span>-->
                        <!--                      <span *ngSwitchCase="'excel'" nz-icon nzType="file-excel"></span>-->
                        <!--                      <span *ngSwitchDefault nz-icon nzType="file"></span>-->
                        <!--                    </ng-container>-->
                        <!--                  </span>-->

                        <div>
                          <nz-form-item>
                            <nz-form-label [nzSpan]="4">名称</nz-form-label>
                            <nz-form-control [nzSpan]="24" [nzErrorTip]="'请输入'+titleName+'名称'" >
                              <input nzSize="large" nz-input
                                     [formControlName]="'name'"
                                     [placeholder]="'请输入'+titleName+'名称'" />
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label [nzSpan]="4">时长</nz-form-label>
                            <nz-form-control [nzSpan]="24" >
                              {{convertTimeToTag(item?.controls['duration'].value)}}
                              <!--                            <input hidden="hidden"-->
                              <!--                                   [formControlName]="'name'"-->
                              <!--                                   [placeholder]="'请输入'+titleName+'名称'" />-->
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label [nzSpan]="4">操作</nz-form-label>
                            <nz-form-control [nzSpan]="24" >
                              <a *ngIf="item?.controls['url'].value" [href]="item?.controls['url'].value" target="_blank" download>
                                <button nz-button nzType="text">
                                  <span nz-icon nzType="download"></span>
                                </button>
                              </a>
                              <!-- 删除按钮 -->
                              <button *ngIf="item?.controls['url'].value" nz-button nzType="text" (click)="removeFileByIndex(i)">
                                <span nz-icon nzType="delete"></span>
                              </button>

                            </nz-form-control>
                          </nz-form-item>
                        </div>

                        <!-- 文件名 -->

                        <!--                      <span class="file-name flex-1 leading-8">-->
                        <!--                        <span style="display: ">{{item.controls.name}}</span>-->
                        <!--                        {{ file.name }}-->
                        <!--                      </span>-->

                        <!--                      <span class="file-name leading-8">[{{ convertTimeToTag(file?.response?.duration) }}]</span>-->
                        <!--                      <span class="file-name leading-8">[{{ convertTimeToTag(item?.controls['duration'].value) }}]</span>-->

                        <!-- 文件状态 -->
                        <!--                      <span class="file-status leading-8" *ngIf="file.status !== 'uploading'">-->
                        <!--                        <ng-container [ngSwitch]="file.status">-->
                        <!--                          <span *ngSwitchCase="'done'" class="text-success">-->
                        <!--                            <span nz-icon nzType="check-circle"></span> 已上传-->
                        <!--&lt;!&ndash;                            <audio [src]="file?.response?.url "></audio>&ndash;&gt;-->
                        <!--                          </span>-->
                        <!--                          <span *ngSwitchCase="'error'" class="text-danger">-->
                        <!--                            <span nz-icon nzType="close-circle"></span> 上传失败-->
                        <!--                          </span>-->
                        <!--                          <span *ngSwitchDefault>{{ file.status }}</span>-->
                        <!--                        </ng-container>-->
                        <!--                      </span>-->
                        <!-- 预览按钮 (仅对图片或PDF) -->
                        <!--                      <button *ngIf="file.status === 'done' && isPreviewable(file)" nz-button nzType="text" (click)="previewFile(file)">-->
                        <!--                        <span nz-icon nzType="eye"></span>-->
                        <!--                      </button>-->

                        <!-- 下载按钮 -->
                        <!--                      <a *ngIf="file?.response?.url && file.status === 'done'" [href]="file?.response?.url" target="_blank" download>-->
                        <!--                      <a *ngIf="item?.controls['url'].value" [href]="item?.controls['url'].value" target="_blank" download>-->
                        <!--                        <button nz-button nzType="text">-->
                        <!--                          <span nz-icon nzType="download"></span>-->
                        <!--                        </button>-->
                        <!--                      </a>-->

                        <!-- 删除按钮 -->
                        <!--                      <button *ngIf="item?.controls['url'].value" nz-button nzType="text" (click)="removeFileByIndex(i)">-->
                        <!--                        <span nz-icon nzType="delete"></span>-->
                        <!--                      </button>-->
                      </div>
                      <div>
                        <!-- 上传进度显示 -->
                        <!--                      <div *ngIf="true || file.status === 'uploading'" class="file-progress">-->
                        <!--                        <nz-progress [nzPercent]="Math.round(file.percent * 100)" [nzShowInfo]="true" nzSize="small"></nz-progress>-->
                        <!--                        &lt;!&ndash;                      <span class="progress-text">{{(file.percent * 100).toFixed(2) }}%</span>&ndash;&gt;-->
                        <!--                      </div>-->
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            <div>
              <app-admin-resource-tags-rel [resourceType]="bisType" [formGroup]="form" [tagsGroupArray]="tagsGroupArray" ></app-admin-resource-tags-rel>
            </div>

            </form>



          </div>


      </div>
    </nz-tab>
  </nz-tabset>
  </nz-spin>
</div>

