import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SchoolService } from '../../../../../services/school.service';
import { ClassService } from '../../../../../services/class.service';
import { UserStatus, UserType } from '../../../../../model/Const';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators, FormArray, UntypedFormControl } from '@angular/forms';
import { CoursewareService } from '../../../../../services/courseware.service';
import { BookItemBarComponent } from './bar';
import { TwNoticeService } from '../../../../../services/tw-notice.service';
import { HWResourceService } from '../../../../../services/huawei-resource.service';
import * as _ from 'lodash';
import { SyllabusService } from '../../../../../services/syllabus.service';
import { MediaV2Service } from '../../../../../services/media-v2.service';
import { TagsService } from '../../../../../services/tags.service';
import { parseInt } from 'lodash';
import { ExerciseService } from '../../../../../services/exercise.service';


@Component({
  selector: 'app-admin-resource-book-form',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class AdminResourceBookFormComponent implements OnInit {
  user: any = null;
  constUserType = UserType;
  currentSyllabusId = 0;
  currentCourseId = 0;

  isLoading = false;
  form!: UntypedFormGroup;

  syllabusList: { syllabus_id: number, course_id: number}[] = [];


  item_id = 0;
  bookAttrs: any = {};
  syllabusChildren = [];
  syllabusKeyValueItems: any = {};

  rootThemesKeyValueItems: any = {};
  subThemesKeyValueItems: any = {};

  relTags: any[] = [];
  bookInfo: any = null;
  flatSyllabusList: any[] = [];


  bisType = 'cartoon';
  tabIndex = 0;
  items_status: any = {};
  // readingStatus = false;
  // classroomReadingStatus = false;

  host = '';
  protocol = '';
  bucket = '';
  exercise_items = [];

  itemService!: CoursewareService|MediaV2Service|ExerciseService;

  constructor(private userService: UserService,
              private clsService: ClassService,
              private syllabusService: SyllabusService,
              private tagsService: TagsService,
              private coursewareService: CoursewareService,
              private mediaService: MediaV2Service,
              private exerciseService: ExerciseService,
              private activatedRoute: ActivatedRoute ,
              private twNoticeService: TwNoticeService ,
              private router: Router ,
              private fb: UntypedFormBuilder,
              private nzMessageService: NzMessageService) {
    this.user = this.userService.getUserInfo();
    const u = new URL(window.location.href);
    this.protocol = u.protocol;
    this.host = HWResourceService.cdnHost;
    this.activatedRoute.title.subscribe({
      next: (title) => {
      }
    });
    console.log('[form] this.activatedRoute', this.activatedRoute.pathFromRoot[2].data);
    this.bisType = this.activatedRoute.pathFromRoot[2].snapshot.url[2].path;
    if (this.bisType === 'video' || this.bisType === 'audio') {
      this.itemService = this.mediaService;
    } else if (this.bisType === 'exercise') {
      this.itemService = this.exerciseService;
    } else {
      this.itemService = this.coursewareService;
    }
    this.activatedRoute.queryParams.subscribe( (query: any) => {
      if (query.tabIndex && parseInt(query.tabIndex, 10) === query.tabIndex * 1) {
        this.tabIndex = parseInt(query.tabIndex, 10);
      }
    });
    // this.activatedRoute.queryParams.subscribe( (query: any) => {
    //   if (query.page && parseInt(query.page, 10) === query.page * 1) {
    //     this.currentPageIndex = parseInt(query.page, 10);
    //   }
    // });


    this.activatedRoute.params.subscribe( params => {
      console.log('params', params);
      this.item_id = +params['item_id'] || 0;
      this.form = this.fb.group({
        id: [ this.item_id ],
        school_id: [ this.user.school_id || 0 ],
        // course_id: [ '', [ Validators.required ] ],
        // theme: [ [] , [Validators.required] ],
        // level: [ '', [Validators.required]  ],
        // themes: this.fb.array([]),
        name: [ '' , [ Validators.required ]],
        // cover: [ '', [ Validators.required ] ],
        cover: [ ''],
        duration: [ '00:00'],
        size: [ '0'],
        url: [ '', (['audio', 'video'].includes(this.bisType) ? [Validators.required] : null) ],
        tagsGroupArray: this.fb.array([])
      });

    });
    // @ts-ignore
    window.aaa = this;
  }

  clearVideo($event: any) {}
  onVideoUploadFailure($event: any) {}
  onVideoOverSize($event: any) {
    this.nzMessageService.error('too large');
  }
  onVideoCoverUploadSuccess(evt: any) {
    this.form.controls['cover'].setValue(evt.url);
  }
  onVideoUploadSuccess(evt: any) {
    if (this.bisType === 'video') {
      this.form.controls['url'].setValue(evt.url);
      const time = parseInt(String(evt.file.duration / 1000), 10);
      this.form.controls['duration'].setValue(time);
      this.form.controls['size'].setValue(evt.file.size);
    }
  }

  clearAudio($event: any) {
    console.log('clearAudio', $event);
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.form.controls['url'].setValue(null);
  }
  onAudioUploadFailure(evt: any) {
    console.log('onAudioUploadFailure', evt);
  }
  onAudioUploadSuccess(evt: any) {
    console.log('onAudioUploadSuccess', evt);
    if (this.bisType === 'audio') {
      this.form.controls['url'].setValue(evt.url);
      // this.form.controls['duration'].setValue(this.formatTime(evt.file.duration / 1000));
      const time = parseInt(String(evt.file.duration / 1000), 10);
      this.form.controls['duration'].setValue(time);
      this.form.controls['size'].setValue(evt.file.size);
    }
  }

  formatTime(time: number) {
    time = parseInt(String(time), 10);
    const sec = time % 60;
    const min = Math.floor(time / 60);
    const hour = Math.floor(time / (60 * 60));
    let t = `${min}:${sec}`;
    if (hour) {
      t = `${hour}:${t}`;
    }
    return t;
  }




  // get themes() {
  //   return this.form.get('themes') as FormArray;
  // }
  get tagsGroupArray () {
    return (this.form.get('tagsGroupArray') || []) as FormArray;
  }

  addTagsGroup({syllabus_id = 0, course_id = 0}: {syllabus_id?: number, course_id?: number, tags?: any[]}) {
    console.log('addTagsGroup1');
    const tagFb = this.fb.group({
      syllabus_id: this.fb.control(+syllabus_id, Validators.required),
      course_id: this.fb.control(+course_id, Validators.required),
    });
    this.tagsGroupArray.push(tagFb);

  }
  removeTagsGroup(index: number): void {
    console.log('removeTagsGroup', index);
    this.tagsGroupArray.removeAt(index);
  }
  clearTagsField({prefix = '', parent = null}: {prefix?: string, parent?: FormGroup|null}) {
    if (!parent) {
      parent = this.form;
    }
    const keys = Object.keys(parent.controls);
    for (let i = 0; i < keys.length; i++) {
      if (keys[i].startsWith(`${prefix}.`)) {
        parent.removeControl(keys[i]);
      }
    }
  }
  addTagsField({id, value = null, prefix = '', parent = null}: {id: any, value?: any, prefix?: string,  parent?: FormGroup|null}): void {
    if (!parent) {
      parent = this.form;
    }
    const tagsControl = [];
    parent.addControl(
      `${prefix}.` + id,
      // new UntypedFormControl(null, Validators.required)
      new UntypedFormControl(value)
    );
  }

  flatListToTreeV2 ({list ,
                      idPaths = ['id'],
                      parentIdPath = 'pid',
                      childListPath = 'children',
                      // tslint:disable-next-line:max-line-length
                      isParent = (node: any) => !node.pid}: {list: any[], idPaths?: string|string[], parentIdPath?: string, childListPath?: string,
    isParent?: (node: any) => Boolean}) {
    if (typeof idPaths === 'string') {
      idPaths = [];
    }
    const rootParents = [];
    // @ts-ignore
    const findValidPathId = (item: any) => {
      for (let i = 0; i < idPaths.length; i++) {
        if (item[idPaths[i]]) {
          return idPaths[i];
        }
      }

    };
    const map: any = {};
    for (const item of list) {
      if (!item[childListPath]) { item[childListPath] = []; }
      const idPath = findValidPathId(item);
      if (!idPath) {
        throw new Error('not found valid id path in tree');
      }
      map[item[idPath]] = item;
    }
    for (const item of list) {
      const parentId = item[parentIdPath];
      if (isParent(item)) {
        rootParents.push(item);
      } else {
        const parentItem = map[parentId];
        if (parentItem) {
          parentItem[childListPath].push(item);
        }
      }
    }
    return rootParents;
  }
  get titleName() {
    let title = '';
    if (this.bisType === 'cartoon') {
      title = '绘本';
    } else if (this.bisType === 'book') {
      title = '绘本';
    } else if (this.bisType === 'audio') {
      title = '音频';
    } else if (this.bisType === 'video') {
      title = '视频';
    } else if (this.bisType === 'exercise') {
      title = '练习';
    }
    return title;
  }
  get actionName() {
    let action = '新增';
    if (this.item_id) {
      action = '编辑';
    }
    return action;
  }
  get backUrl () {
    return `/admin/v3/resource/${this.bisType}/list`;
  }
  ngOnInit() {
    this.isLoading = true;
    // this.coursewareService.bookAttrs(this.bisType).then((bookAttrs) => {
    this.syllabusService.category(this.bisType).then((bookAttrs) => {
      console.log('bookAttrs', bookAttrs);
      const _themesKeyValueItems  = _.groupBy(bookAttrs.themes, 'syllabus_id');
      this.flatSyllabusList = this.flattenTree(bookAttrs.syllabus);
      this.flatSyllabusList.forEach((syllabus: any) => {
        if (typeof syllabus.path === 'string') {
          const pathParts = syllabus.path.split('/');
          syllabus.path_parts = [ ...pathParts.filter((p: string) => p.trim()).map((path: any) => +path), syllabus.id];
        }
      });

      // this.rootThemesKeyValueItems = _.keyBy(bookAttrs.syllabus, 'id');
      // this.rootThemesKeyValueItems = _.groupBy(bookAttrs.syllabus, 'id');
      this.rootThemesKeyValueItems = _.groupBy(bookAttrs.themes, 'syllabus_id');
      // @ts-ignore
      // this.rootThemesKeyValueItems2 = _.groupBy(bookAttrs.syllabus, 'id');
      this.subThemesKeyValueItems = _themesKeyValueItems;

      this.syllabusKeyValueItems = _.keyBy(this.flatSyllabusList, 'id');

      const tagsGroupItems = _.groupBy(bookAttrs.themes, 'syllabus_id');
      console.log('tag group ', tagsGroupItems);

      this.bookAttrs = bookAttrs;
      if (this.item_id) {
        this.itemService.fetch(this.bisType, this.item_id).then((result) => {
          console.log('itemService.fetch', result);
          this.exercise_items = result.exercise_items || [];
          this.bookInfo = result;
          this.form.controls['name'].setValue(result.name);
          this.form.controls['size'].setValue(result.size);
          this.form.controls['duration'].setValue(result.duration);
          // tslint:disable-next-line:max-line-length
          const cover = (result.cover && (result.cover.startsWith('http') ? result.cover : this.protocol + '//' + this.host + '/' + (this.bucket ? this.bucket + '/' : '') + result.cover));
          this.form.controls['cover'].setValue(cover);

          if (result.url && this.form.controls['url'] ) {
            // tslint:disable-next-line:max-line-length
            const url = (result.url && (result.url.startsWith('http') ? result.url : this.protocol + '//' + this.host + '/' + (this.bucket ? this.bucket + '/' : '') + result.url));
            this.form.controls['url'].setValue(url);
          }


          this.items_status = result.items_status;
          // this.relTags = result.themes;
          this.relTags = result.themes;

          let fieldIndex = 0;
          // result.syllabus
          this.relTags.forEach((tag: any, i: number) => {
            const tg = this.syllabusKeyValueItems[tag.syllabus_id];
            if (+tag.syllabus_id && !tg) {
              return;
            }
            // const tg = this.findParentTag(bookAttrs.themes, tagGroup.syllabus_id);
            const syllabus_id = tg?.path_parts?.length > 0 ? tg?.path_parts[0] : 0;
            const course_id = tg?.path_parts?.length > 1 ? tg?.path_parts[1] : 0;
            this.addTagsGroup({syllabus_id, course_id} );
            this.changeSyllabus(i, syllabus_id);
            this.changeCourse(i, course_id);
            fieldIndex++;

            // syllabusChildren
            // const groupArray = this.form.controls['tagsGroupArray'] as FormArray;
            // const group = groupArray.at(groupArray.length - 1) as FormGroup;
            // tagGroup.tags.forEach((v: any) => {
            //   this.addTagsField({id: v.tag_group_id , value: v.tag_id, prefix: 'tag_group_id', parent: group});
            // });
          });




          this.isLoading = false;
        }).catch(err => {
            throw err;
        });
      } else {
        this.isLoading = false;
      }

    }).catch(err => {
      this.isLoading = false;
      this.nzMessageService.error(err.message || 'System Error');
    });
  }


  flattenTree(tree: any): any {
    return _.flatMap(tree, (node) => {
      const { children, ...rest } = node;
      return [rest, ...this.flattenTree(children)];
    });
  }

  findParentTag(targetList: any, id: any) {
    for (let i = 0; i < targetList.length; i++) {
      const target = targetList[i];
      const find = target.children.find((child: any) => +child.id === +id);
      if (find) {
        return target;
      }
    }

  }

  currentSubOptions (group_index: number) {
    const group = (this.tagsGroupArray.controls[group_index] as FormGroup);
    const syllabus_id = group.controls['syllabus_id'];
    // const a = this.rootThemesKeyValueItems[syllabus_id.value];
    // return a?.children;
    const item = this.bookAttrs.syllabus.find((s: any) => {
      return +s.id === +syllabus_id.value;
    });
    return  item?.children;
  }

  currentRootTags() {

  }

  changeSyllabus(group_index: number, syllabus_id: number) {
    console.log('changeSyllabus', group_index, syllabus_id);
    // if (!(+syllabus_id)) {
    //   return;
    // }
    const group = (this.tagsGroupArray.controls[group_index] as FormGroup);
    group.controls['course_id'].reset(-1);
    this.clearTagsField({prefix: 'tag_group_id', parent: group});
    // this.form.controls['course_id'].setValue('');
    if (typeof syllabus_id === 'undefined') {
      this.currentSyllabusId = 0;
      this.currentCourseId = 0;
      this.syllabusChildren = [];
      return;
    }
    // const item = this.bookAttrs.syllabus.find((s: any) => {
    //   return +s.id === +syllabus_id;
    // });
    // const items = this.bookAttrs.syllabus.filter((s: any) => {
    //   return +s.pid === +syllabus_id;
    // });
    // this.syllabusChildren = items;
    this.currentSyllabusId = syllabus_id;
    const selectedTags = this.rootThemesKeyValueItems[syllabus_id] || [];
    const tagGroupItem = this.relTags.find(tag => +tag.syllabus_id === +syllabus_id);
    const tags = this.bookAttrs.themes.filter((tag: any) => +tag.syllabus_id === +syllabus_id);
    const a = this.bookInfo;
    tags.forEach((tag: any) => {
      // const value = tag.children.map((v: any) => v.id);
      const tagItem = tagGroupItem?.tags.find((s: any) => {
        return s.tag_group_id === +tag.id;
      });
      this.addTagsField({
        id: tag.id,
        value: tagItem?.tag_id ,
        // value: tagGroupItem?.tag_id ,
        prefix: 'tag_group_id',
        parent: group});
    });
  }

  changeCourse(group_index: number, course_id: number) {
    console.log('changeCourse', group_index, course_id);

    if (!(+course_id) || !course_id) {
      return;
    }
    const group = (this.tagsGroupArray.controls[group_index] as FormGroup);
    group.controls['course_id'].setValue(+course_id, {emitViewToModelChange: false});
    // group.controls['course_id'].reset(+course_id);
    this.clearTagsField( {prefix: 'tag_id', parent: group});
    if (!course_id) {
      this.currentCourseId = 0;
      return;
    }
    // const item = this.bookAttrs.themes.filter((s: any) => {
    //   return +s.syllabus_id === +syllabus_id;
    // });
    this.currentCourseId = course_id;
    const selectedTags = this.subThemesKeyValueItems[course_id] || [];
    const tagGroupItem = this.relTags.find(t => +t.syllabus_id === +course_id);
    const tags = this.bookAttrs.themes.filter((tag: any) => +tag.syllabus_id === +course_id);
    tags.forEach((tag: any) => {
      const value = tag.children.map((v: any) => v.id);
      const tagItem = tagGroupItem?.tags.find((s: any) => {
        return s.tag_group_id === +tag.id;
      });
      this.addTagsField({
        value: tagItem?.tag_id,
        id: tag.id,
        prefix: 'tag_id',
        parent: group});
    });
  }

  tabIndexChange($evt: any) {
    console.log('tabIndexChange', $evt, this.tabIndex);
    this.tabIndex = $evt;
  }
  noticeReadingStatus() {
    this.twNoticeService.warning({nzContent: '請先上傳Reading素材'});
  }
  setCover(event: any) {
    this.form.controls['cover'].setValue(event.url);
  }

  submitForm = ($event: any, value: any) => {
    if ($event) {
      $event.preventDefault();
    }
    if (this.isLoading) {
      return;
    }
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsDirty();
      this.form.controls[ key ].updateValueAndValidity();
    }
    console.log('submitForm', $event, value);
    let tagValid = true;
    value.tagsGroupArray.forEach((tag: any) => {
      const {course_id, syllabus_id} = tag;
      if (course_id <= 0 || syllabus_id < 0) {
        tagValid = false;
      }
    });

    if (!tagValid || !value.tagsGroupArray || (Array.isArray(value.tagsGroupArray) && value.tagsGroupArray.length === 0)) {
      this.nzMessageService.error('请正确关联分类');
      return;
    }
    this.isLoading = true;
    this.itemService.save(this.bisType, value).then((result) => {
      this.isLoading = false;
      const modal = this.twNoticeService.success({nzContent: '保存成功'});
      modal.afterClose.subscribe(() => {
        if (!this.item_id) {
          this.router.navigate([`/admin/v3/resource/${this.bisType}`]);
        }

      });
    }).catch(err => {
      this.isLoading = false;
      this.nzMessageService.error(err.message || 'System Error');
    });
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.form.reset();
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsPristine();
      this.form.controls[ key ].updateValueAndValidity();
    }
  }

  dragStart($evt: any) {
    console.log('dragStart', $evt);
  }
  dragging($evt: any) {
    console.log('dragging', $evt);
  }

  reloadData() {
    this.ngOnInit();
  }

  protected readonly UserStatus = UserStatus;
  protected readonly Boolean = Boolean;
  protected readonly JSON = JSON;
  protected readonly Object = Object;
  protected readonly Array = Array;
  protected readonly parseInt = parseInt;
}
