import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';
import {AdminComponent} from './components/admin/admin.component';
import {AdminAuthGuard} from './guard/admin-auth.guard';
import {NotFoundComponent} from './components/admin/404/not-found.component';
import {UserLoginComponent} from './components/frontend/pages/login/user-login.component';
import {UserClassesComponent} from './components/frontend/pages/classes/user-classes.component';
import {UserAuthGuard} from './guard/user-auth.guard';
import {UserBookPlayerComponent} from './components/frontend/pages/book-player/user-book-player.component';
import {UserType} from './model/Const';
import {ClassIndexComponent} from './components/admin/pages/classes/index/index.component';
import {TeachingIndexComponent} from './components/admin/pages/teachingmaterial/index/index.component';
import {ClassAddComponent} from './components/admin/pages/classes/add/add.component';
import {TeachersIndexComponent} from './components/admin/pages/teachers/index/index.component';
import {TeachersAddComponent} from './components/admin/pages/teachers/add/add.component';
import {UserBooksComponent} from './components/frontend/pages/books/user-books.component';
import {StudentsIndexComponent} from './components/admin/pages/students/index/index.component';
import {StudentsAddComponent} from './components/admin/pages/students/add/add.component';
import {SchoolsIndexComponent} from './components/admin/pages/schools/index/index.component';
import {SchoolsAddComponent} from './components/admin/pages/schools/add/add.component';
import {ClassStatisticsComponent} from './components/admin/pages/classes/statistics/statistics.component';
import {ClassStatisticsDetailComponent} from './components/admin/pages/classes/statistics-detail/statistics-detail.component';
import {ClassStatisticsMonthlyComponent} from './components/admin/pages/classes/statistics-monthly/statistics-monthly.component';
import {ClassStatisticsQuarterComponent} from './components/admin/pages/classes/statistics-quarter/statistics-quarter.component';
import {TabletManageIndexComponent} from './components/admin/pages/admin/tablet-manage/index/index.component';
import {AdminSearchComponent} from './components/admin/pages/admin/search/search.component';
import {AdminBatchAddUserComponent} from './components/admin/pages/admin/batch-add-user/batch-add-user.component';
import {AppManagerComponent} from './components/admin/pages/admin/app-manager/app-manager.component';
import {StudentsBatchAddComponent} from './components/admin/pages/students/batch_add/batch_add.component';
import {AdminUserSettingComponent} from './components/admin/pages/admin/user-setting/user-setting.component';
import {OrdersIndexComponent} from './components/admin/pages/admin/orders/index/orders-index.component';
import {OrdersBuyComponent} from './components/admin/pages/admin/orders/buy/orders-buy.component';
import {OrderInfoComponent} from './components/admin/pages/admin/orders/index/order-info.component';
import {PromotionsIndexComponent} from './components/admin/pages/admin/promotions/index/index.component';
import {PromotionsAddComponent} from './components/admin/pages/admin/promotions/add/add.component';
import {StudentsSuperManageIndexComponent} from './components/admin/pages/students/super-manage/manage.component';
import {UserBooksResourceComponent} from './components/frontend/pages/resource/user-books-resource.component';
import {GiftsAddComponent} from './components/admin/pages/admin/coin-gift-storage/add/gifts-add.component';
import {GiftsIndexComponent} from './components/admin/pages/admin/coin-gift-storage/index/gifts-index.component';
import {UserAuditIndexComponent} from './components/admin/pages/admin/user-audit/index/index.component';
import {environment} from '../environments/environment';
import {LessonResourceVideoThemeComponent} from './components/admin/pages/admin/lesson-resource/theme/video.component';
import {LessonResourceAudioThemeComponent} from './components/admin/pages/admin/lesson-resource/theme/audio.component';
import {QupeiyinIndexComponent} from './components/admin/pages/admin/qupeiyin/index/qupeiyin-index.component';
import {QupeiyinFormComponent} from './components/admin/pages/admin/qupeiyin/form/qupeiyin-form.component';
import {VideoCategoriesComponent} from './components/admin/pages/admin/pad/video-categories/video-categories.component';
import {PadVideoListComponent} from './components/admin/pages/admin/pad/video-list/pad-video-list.component';
import {AppLogComponent} from './components/admin/pages/log-manager/app-log/app-log.component';
import {ElpFirmwareAddComponent} from './components/admin/pages/admin/elp-speaker/firmware/add/add.component';
import {ElpFirmwareIndexComponent} from './components/admin/pages/admin/elp-speaker/firmware/index/index.component';
import {TabletManageImportComponent} from './components/admin/pages/admin/tablet-manage/import/index.component';
import {ElpLogIndexComponent} from './components/admin/pages/admin/elp-speaker/logs/index.component';
import {TabletManageSingleIndexComponent} from './components/admin/pages/admin/tablet-manage-single/index/index.component';
import {ElpTraceIndexComponent} from './components/admin/pages/admin/elp-speaker/trace/index.component';
import {VideoShowComponent} from './components/admin/pages/admin/lesson-resource/video-show/video-show.component';
import {EnrollmentComponent} from './components/admin/pages/admin/enrollment/enrollment.component';
import { AdminBookResourceCoursewareListComponent } from './components/admin/v3/resource/coursewares/index.component';
import { AdminBookCategoryIndexComponent } from './components/admin/v3/resource/categary/index.component';
import { AdminResourceBookFormComponent } from './components/admin/v3/resource/form/index.component';
import { AdminResourceBookItemReadingComponent } from './components/admin/v3/resource/relate/reading/index.component';
import { AdminResourceBookItemTouchToReadComponent } from './components/admin/v3/resource/relate/touch-to-read/index.component';
import { AdminMediaResourceListComponent } from './components/admin/v3/resource/media/index.component';
import { AdminMediaCategoryIndexComponent } from './components/admin/v3/resource/media/categary/index.component';
import { AdminResourceMediaFormComponent } from './components/admin/v3/resource/media/form/index.component';
import { AdminResourceBookListComponent } from './components/admin/v3/resource/list/index.component';
import { AdminResourceBookItemAudioComponent } from './components/admin/v3/resource/relate/audio/index.component';
import { AdminResourceBookItemVideoComponent } from './components/admin/v3/resource/relate/video/index.component';
import { AdminResourceBookItemQuizComponent } from './components/admin/v3/resource/relate/quiz/index.component';
import {AdminBookCategoryTemplateComponent} from './components/admin/v3/resource/categary/template/index.component';
import { RedirectLinkExternal } from './components/admin/v3/resource/redirect-link-external';
import { AdminResourceItemBatchAddComponent } from './components/admin/v3/resource/batch-add/index.component';


type IRouteMenu = Route & { name?: string };


const routes: Routes = [
    {path: '', redirectTo: '/login', pathMatch: 'full'},
    // fontend start
    {path: 'login', component: UserLoginComponent},
    {
        path: 'pad',
        children: [
            {path: '', redirectTo: 'video/categories', pathMatch: 'full'},
            {
                path: 'video/categories',
                // @ts-ignore
                name: '视频管理',
                // @ts-ignore
                icon: 'video',
                data: {role: []},
                canActivate: [],
                component: VideoCategoriesComponent,
            },
            {
                path: 'video/category/:id',
                // @ts-ignore
                name: '视频列表',
                // @ts-ignore
                icon: 'video',
                hidden: true,
                data: {role: []},
                canActivate: [],
                component: PadVideoListComponent,
            },
        ]
    },
    {
        path: 'user/classes', component: UserClassesComponent,
        data: {
            view: 'classes',
            role: [UserType.SCHOOL_ADMIN, UserType.TEACHER]
        },
        canActivate: [UserAuthGuard]
    },
    {
        path: 'user/books', component: UserBooksComponent,
        data: {view: 'books', role: [UserType.SCHOOL_ADMIN, UserType.TEACHER, UserType.STUDENT]}, canActivate: [UserAuthGuard]
    },
    {
        path: 'user/books/:bookId/resource',
        component: UserBooksResourceComponent,
        data: {view: 'books', role: [UserType.SCHOOL_ADMIN, UserType.TEACHER, UserType.STUDENT]},
        canActivate: [UserAuthGuard]
    },
    {
        path: 'storybook/:book_id',
        component: UserBookPlayerComponent,
        data: {role: [UserType.SCHOOL_ADMIN, UserType.TEACHER, UserType.STUDENT]},
        canActivate: [UserAuthGuard]
    },
    {
        path: 'admin',
        component: AdminComponent,
        // canActivate: [AuthGuard],
        children: [
            {
                path: 'index',
                data: {role: [UserType.SUPER_ADMIN]},
                canActivate: [AdminAuthGuard],
                component: ClassIndexComponent
            },
            {
                path: 'v3/resource/cartoon',
                // @ts-ignore
                name: '绘本资源管理',
                icon: 'team',

                canActivate: [AdminAuthGuard],
                data: {role: [UserType.SUPER_ADMIN, UserType.TEACH_RESEARCHER/*, UserType.TEACHER, UserType.SCHOOL_ADMIN*/]},
                children: [
                    {path: '', redirectTo: 'list', pathMatch: 'full'},

                    {
                        path: 'list',
                        // @ts-ignore
                        name: '绘本管理',
                        title: '绘本管理',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        // component: AdminBookResourceCoursewareListComponent,
                        component: RedirectLinkExternal,
                        params: {bis_type: 'cartoon'},
                        // hidden: true,
                    },
                    {
                        path: 'add',
                        // @ts-ignore
                        name: '新增绘本',
                        title: '新增绘本',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        component: AdminResourceBookFormComponent,
                        hidden: true
                    },
                    {
                        path: 'edit/:item_id',
                        // @ts-ignore
                        name: '编辑绘本',
                        title: '编辑绘本',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        // component: AdminResourceBookFormComponent,
                        hidden: true,
                        children: [
                            { path: '', redirectTo: 'index', pathMatch: 'full' },
                            {
                                path: 'index',
                                // @ts-ignore
                                name: '编辑绘本',
                                title: '编辑绘本',
                                // data: { role: [UserType.SUPER_ADMIN]},
                                data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                                canActivate: [AdminAuthGuard],
                                component: AdminResourceBookFormComponent,
                                hidden: true

                            },
                            {
                                path: 'reading',
                                // @ts-ignore
                                name: '编辑绘本Reading',
                                title: '编辑绘本Reading',
                                // data: { role: [UserType.SUPER_ADMIN]},
                                data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                                canActivate: [AdminAuthGuard],
                                component: AdminResourceBookItemReadingComponent,
                                hidden: true
                            },
                            {
                                path: 'touch-to-read',
                                // @ts-ignore
                                name: '编辑绘本点读',
                                title: '编辑绘本点读',
                                // data: { role: [UserType.SUPER_ADMIN]},
                                data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                                canActivate: [AdminAuthGuard],
                                component: AdminResourceBookItemTouchToReadComponent,
                                hidden: true
                            },
                            {
                                path: 'audio',
                                // @ts-ignore
                                name: '编辑绘本音频',
                                title: '编辑绘本音频',
                                // data: { role: [UserType.SUPER_ADMIN]},
                                data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                                canActivate: [AdminAuthGuard],
                                component: AdminResourceBookItemAudioComponent,
                                hidden: true
                            },
                            {
                                path: 'video',
                                // @ts-ignore
                                name: '编辑绘本视频',
                                title: '编辑绘本视频',
                                // data: { role: [UserType.SUPER_ADMIN]},
                                data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                                canActivate: [AdminAuthGuard],
                                component: AdminResourceBookItemVideoComponent,
                                hidden: true
                            },
                            {
                                path: 'quiz',
                                // @ts-ignore
                                name: '编辑绘本Quiz',
                                title: '编辑绘本Quiz',
                                // data: { role: [UserType.SUPER_ADMIN]},
                                data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                                canActivate: [AdminAuthGuard],
                                component: AdminResourceBookItemQuizComponent,
                                hidden: true
                            },

                        ]
                    },


                    {
                        path: 'category',
                        // @ts-ignore
                        name: '绘本分类管理',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        component: AdminBookCategoryIndexComponent,
                    },
                    {
                        path: 'category/template',
                        // @ts-ignore
                        name: '模板管理',
                        title: '模板管理',
                        data: { role: [UserType.SUPER_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        component: AdminBookCategoryTemplateComponent,
                        hidden: true
                    }

                ]
            },

            {
                path: 'v3/resource/book',
                // @ts-ignore
                name: '教材资源管理',
                icon: 'team',
                canActivate: [AdminAuthGuard],
                // hidden: true,
                // data: {role: [UserType.SUPER_ADMIN]},
                data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                children: [
                    {path: '', redirectTo: 'list', pathMatch: 'full'},


                    {
                        path: 'list',
                        // @ts-ignore
                        name: '教材管理',
                        title: '教材管理',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        // component: AdminBookResourceCoursewareListComponent,
                        // hidden: true,
                        component: RedirectLinkExternal,
                        params: {bis_type: 'book'},
                    },
                    {
                        path: 'add',
                        // @ts-ignore
                        name: '新增教材',
                        title: '新增教材',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        component: AdminResourceBookFormComponent,
                        hidden: true
                    },
                    {
                        path: 'edit/:item_id',
                        // @ts-ignore
                        name: '编辑教材',
                        title: '编辑教材',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        // component: AdminResourceBookFormComponent,
                        hidden: true,
                        children: [
                            { path: '', redirectTo: 'index', pathMatch: 'full' },
                            {
                                path: 'index',
                                // @ts-ignore
                                name: '编辑教材',
                                title: '编辑教材',
                                // data: { role: [UserType.SUPER_ADMIN]},
                                data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                                canActivate: [AdminAuthGuard],
                                component: AdminResourceBookFormComponent,
                                hidden: true
                            },
                            {
                                path: 'reading',
                                // @ts-ignore
                                name: '编辑教材Reading',
                                title: '编辑教材Reading',
                                // data: { role: [UserType.SUPER_ADMIN]},
                                data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                                canActivate: [AdminAuthGuard],
                                component: AdminResourceBookItemReadingComponent,
                                hidden: true
                            },
                            {
                                path: 'touch-to-read',
                                // @ts-ignore
                                name: '编辑教材点读',
                                title: '编辑教材点读',
                                // data: { role: [UserType.SUPER_ADMIN]},
                                data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                                canActivate: [AdminAuthGuard],
                                component: AdminResourceBookItemTouchToReadComponent,
                                hidden: true
                            },
                        ]
                    },



                    {
                        path: 'category',
                        // @ts-ignore
                        name: '教材分类管理',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        component: AdminBookCategoryIndexComponent,
                    },
                    {
                        path: 'category/template',
                        // @ts-ignore
                        name: '模板管理',
                        title: '模板管理',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        component: AdminBookCategoryTemplateComponent,
                        hidden: true
                    }


                ]
            },




            {
                path: 'v3/resource/audio',
                // @ts-ignore
                name: '音频资源管理',
                icon: 'team',
                canActivate: [AdminAuthGuard],
                // data: {role: [UserType.SUPER_ADMIN]},
                data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                    {
                        path: 'list',
                        // @ts-ignore
                        name: '音频管理',
                        title: '音频管理',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        // TODO here
                        // component: AdminMediaResourceListComponent,
                        component: AdminBookResourceCoursewareListComponent,
                    },
                    {
                        path: 'add',
                        // @ts-ignore
                        name: '新增音频',
                        title: '新增音频',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        // component: AdminResourceMediaFormComponent,
                        component: AdminResourceBookFormComponent,

                        hidden: true
                    },
                    {
                        path: 'batch-add',
                        // @ts-ignore
                        name: '批量新增音频',
                        title: '批量新增音频',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        // component: AdminResourceMediaFormComponent,
                        component: AdminResourceItemBatchAddComponent,
                        hidden: true
                    },
                    {
                        path: 'edit/:item_id',
                        // @ts-ignore
                        name: '编辑音频',
                        title: '编辑音频',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        // component: AdminResourceMediaFormComponent,
                        component: AdminResourceBookFormComponent,
                        hidden: true,
                    },
                    {
                        path: 'category',
                        // @ts-ignore
                        name: '音频分类管理',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        // component: AdminMediaCategoryIndexComponent,
                        component: AdminBookCategoryIndexComponent,
                    },
                ]
            },

            {
                path: 'v3/resource/video',
                // @ts-ignore
                name: '视频资源管理',
                icon: 'team',
                canActivate: [AdminAuthGuard],
                // data: {role: [UserType.SUPER_ADMIN]},
                data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                    {
                        path: 'list',
                        // @ts-ignore
                        name: '视频管理',
                        title: '视频管理',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        // component: AdminMediaResourceListComponent,
                        component: AdminBookResourceCoursewareListComponent,
                    },
                    {
                        path: 'add',
                        // @ts-ignore
                        name: '新增视频',
                        title: '新增视频',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        component: AdminResourceBookFormComponent,
                        hidden: true
                    },
                    {
                        path: 'batch-add',
                        // @ts-ignore
                        name: '批量新增视频',
                        title: '批量新增视频',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        component: AdminResourceItemBatchAddComponent,
                        hidden: true
                    },
                    {
                        path: 'edit/:item_id',
                        // @ts-ignore
                        name: '编辑视频',
                        title: '编辑视频',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        component: AdminResourceBookFormComponent,
                        hidden: true,
                    },
                    {
                        path: 'category',
                        // @ts-ignore
                        name: '视频分类管理',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        component: AdminBookCategoryIndexComponent,
                    },
                ]
            },


            {
                path: 'v3/resource/exercise',
                // @ts-ignore
                name: '练习资源管理',
                icon: 'team',
                canActivate: [AdminAuthGuard],
                // data: {role: [UserType.SUPER_ADMIN]},
                data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                children: [
                    {path: '', redirectTo: 'list', pathMatch: 'full'},


                    {
                        path: 'list',
                        // @ts-ignore
                        name: '练习管理',
                        title: '练习管理',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        component: AdminBookResourceCoursewareListComponent,

                    },
                    {
                        path: 'add',
                        // @ts-ignore
                        name: '新增练习',
                        title: '新增练习',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        component: AdminResourceBookFormComponent,
                        hidden: true
                    },
                    {
                        path: 'edit/:item_id',
                        // @ts-ignore
                        name: '编辑练习',
                        title: '编辑练习',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        // component: AdminResourceBookFormComponent,
                        hidden: true,
                        children: [
                            { path: '', redirectTo: 'index', pathMatch: 'full' },
                            {
                                path: 'index',
                                // @ts-ignore
                                name: '编辑练习',
                                title: '编辑练习',
                                // data: { role: [UserType.SUPER_ADMIN]},
                                data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                                canActivate: [AdminAuthGuard],
                                component: AdminResourceBookFormComponent,
                                hidden: true
                            },
                            {
                                path: 'reading',
                                // @ts-ignore
                                name: '编辑教材Reading',
                                title: '编辑教材Reading',
                                // data: { role: [UserType.SUPER_ADMIN]},
                                data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                                canActivate: [AdminAuthGuard],
                                component: AdminResourceBookItemReadingComponent,
                                hidden: true
                            },
                            {
                                path: 'touch-to-read',
                                // @ts-ignore
                                name: '编辑教材点读',
                                title: '编辑教材点读',
                                // data: { role: [UserType.SUPER_ADMIN]},
                                data: {role: [UserType.SUPER_ADMIN, UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                                canActivate: [AdminAuthGuard],
                                component: AdminResourceBookItemTouchToReadComponent,
                                hidden: true
                            },
                        ]
                    },



                    {
                        path: 'category',
                        // @ts-ignore
                        name: '习题分类管理',
                        // data: { role: [UserType.SUPER_ADMIN]},
                        data: {role: [UserType.SUPER_ADMIN,  UserType.TEACHER, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        component: AdminBookCategoryIndexComponent,
                    }
                ]
            },


            /*

            {
                path: 'v3/resource/book',
                // @ts-ignore
                name: '[test]Book资源管理',
                icon: 'team',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.SUPER_ADMIN]},
                children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                    {
                        path: 'list',
                        // @ts-ignore
                        name: 'Book管理',
                        title: 'Book管理',
                        data: { role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: AdminResourceBookListComponent,
                    },
                    {
                        path: 'add',
                        // @ts-ignore
                        name: '新增Book',
                        title: '新增Book',
                        data: { role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: AdminResourceBookFormComponent,
                        hidden: true
                    },
                    {
                        path: 'edit/:item_id',
                        // @ts-ignore
                        name: '编辑Book',
                        title: '编辑Book',
                        data: { role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: AdminResourceBookFormComponent,
                        hidden: true,
                    },
                    {
                        path: 'category',
                        // @ts-ignore
                        name: 'Book分类管理',
                        data: { role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: AdminBookCategoryIndexComponent,
                    },
                ]
            },
            {
                path: 'v3/resource/exercise',
                // @ts-ignore
                name: '[test]Test资源管理',
                icon: 'team',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.SUPER_ADMIN]},
                children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                    {
                        path: 'list',
                        // @ts-ignore
                        name: 'Test管理',
                        title: 'Test管理',
                        data: { role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: AdminResourceBookListComponent,
                    },
                    {
                        path: 'add',
                        // @ts-ignore
                        name: '新增Test',
                        title: '新增Test',
                        data: { role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: AdminResourceBookFormComponent,
                        hidden: true
                    },
                    {
                        path: 'edit/:item_id',
                        // @ts-ignore
                        name: '编辑Test',
                        title: '编辑Test',
                        data: { role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: AdminResourceBookFormComponent,
                        hidden: true,
                    },
                    {
                        path: 'category',
                        // @ts-ignore
                        name: 'Test分类管理',
                        data: { role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: AdminBookCategoryIndexComponent,
                    },
                ]
            },
            {
                path: 'v3/resource/audio',
                // @ts-ignore
                name: '[test]音频资源管理',
                icon: 'team',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.SUPER_ADMIN]},
                children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                    {
                        path: 'list',
                        // @ts-ignore
                        name: '音频管理',
                        title: '音频管理',
                        data: { role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: AdminMediaResourceListComponent,
                    },
                    {
                        path: 'add',
                        // @ts-ignore
                        name: '新增音频',
                        title: '新增音频',
                        data: { role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: AdminResourceMediaFormComponent,
                        hidden: true
                    },
                    {
                        path: 'edit/:item_id',
                        // @ts-ignore
                        name: '编辑音频',
                        title: '编辑音频',
                        data: { role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: AdminResourceMediaFormComponent,
                        hidden: true,
                    },
                    {
                        path: 'category',
                        // @ts-ignore
                        name: '音频分类管理',
                        data: { role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        // component: AdminMediaCategoryIndexComponent,
                        component: AdminBookCategoryIndexComponent,
                    },
                ]
            },
            {
                path: 'v3/resource/video',
                // @ts-ignore
                name: '[test]视频资源管理',
                icon: 'team',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.SUPER_ADMIN]},
                children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                    {
                        path: 'list',
                        // @ts-ignore
                        name: '视频管理',
                        title: '视频管理',
                        data: { role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: AdminMediaResourceListComponent,
                    },
                    {
                        path: 'add',
                        // @ts-ignore
                        name: '新增视频',
                        title: '新增视频',
                        data: { role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: AdminResourceBookFormComponent,
                        hidden: true
                    },
                    {
                        path: 'edit/:item_id',
                        // @ts-ignore
                        name: '编辑视频',
                        title: '编辑视频',
                        data: { role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: AdminResourceBookFormComponent,
                        hidden: true,
                    },
                    {
                        path: 'category',
                        // @ts-ignore
                        name: '视频分类管理',
                        data: { role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: AdminMediaCategoryIndexComponent,
                    },
                ]
            },

            */




            {
                path: 'schools',
                name: '学校管理',
                icon: 'team',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.SUPER_ADMIN, UserType.PURCHASE_ACCOUNT_ADMIN]},
                // component: StorybookIndexComponent,
                children: [
                    {path: '', redirectTo: 'index', pathMatch: 'full'},
                    {
                        path: 'index',
                        // @ts-ignore
                        icon: 'team',
                        data: {role: [UserType.SUPER_ADMIN, UserType.PURCHASE_ACCOUNT_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: SchoolsIndexComponent,
                        hidden: true
                    },
                    {
                        path: 'add',
                        // @ts-ignore
                        name: '添加学校',
                        icon: 'plus',
                        data: {role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: SchoolsAddComponent,
                        hidden: true
                    },
                    {
                        path: 'edit/:id',
                        // @ts-ignore
                        name: '编辑学校',
                        icon: 'plus',
                        data: {role: [UserType.SCHOOL_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: SchoolsAddComponent,
                        hidden: true
                    },
                ]
            },

            {
                path: 'search',
                // @ts-ignore
                name: '用户信息查询',
                icon: 'area-chart',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.SUPER_ADMIN, UserType.PURCHASE_ACCOUNT_ADMIN]},
                component: AdminSearchComponent
            },
            {
                path: 'user',
                name: '用户管理',
                icon: 'area-chart',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.SUPER_ADMIN]},
                // component: AdminSearchComponent
                children: [
                    {path: '', redirectTo: 'index', pathMatch: 'full'},
                    {
                        path: 'index',
                        // @ts-ignore
                        name: '批量添加学生',
                        icon: 'team',
                        data: {role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: AdminBatchAddUserComponent,
                        hidden: false
                    },
                ]
            },
            {
                path: 'app',
                // @ts-ignore
                name: 'app 版本管理',
                icon: 'android',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.SUPER_ADMIN]},
                // component: AppManagerComponent
                children: [
                    {path: '', redirectTo: 'index', pathMatch: 'full'},
                    {
                        path: 'ireadabc',
                        // @ts-ignore
                        name: '全阅读APP',
                        icon: 'team',
                        data: {role: [UserType.SUPER_ADMIN], appIdentity: 'ireadabc'},
                        canActivate: [AdminAuthGuard],
                        component: AppManagerComponent,
                        hidden: false
                    },
                    {
                        path: 'ireadabc_resources',
                        // @ts-ignore
                        name: '全阅读资源APP',
                        icon: 'team',
                        data: {role: [UserType.SUPER_ADMIN], appIdentity: 'ireadabc_resources'},
                        canActivate: [AdminAuthGuard],
                        component: AppManagerComponent,
                        hidden: false
                    },
                ]
            },
            {
                path: 'orders',
                // @ts-ignore
                name: '订单管理',
                icon: 'money-collect',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.SUPER_ADMIN]},
                component: OrdersIndexComponent
            },
            {
                path: 'order-info/:id',
                // @ts-ignore
                name: '订单审核',
                icon: 'money-collect',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.SUPER_ADMIN]},
                component: OrderInfoComponent,
                hidden: true
            },
            {
                path: 'tablet-manage',
                // @ts-ignore
                name: '双系统平板管理',
                icon: 'mobile',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.PAD_SYSTEM_ADMIN, UserType.SUPER_ADMIN]},
                children: [
                    {path: '', redirectTo: 'index', pathMatch: 'full'},
                    {
                        path: 'index',
                        // @ts-ignore
                        name: '平板mac列表',
                        // @ts-ignore
                        icon: 'setting',
                        data: {role: [UserType.PAD_SYSTEM_ADMIN, UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: TabletManageIndexComponent,
                        hidden: false
                    },
                    {
                        path: 'import',
                        // @ts-ignore
                        name: '导入设备mac',
                        // @ts-ignore
                        icon: 'setting',
                        data: {role: [UserType.PAD_SYSTEM_ADMIN, UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: TabletManageImportComponent,
                        hidden: false
                    },
                ]
            },
            {
                path: 'tablet-manage-single',
                // @ts-ignore
                name: '单系统平板管理',
                icon: 'mobile',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.SUPER_ADMIN, UserType.PAD_SYSTEM_ADMIN]},
                children: [
                    {path: '', redirectTo: 'index', pathMatch: 'full'},
                    {
                        path: 'index',
                        // @ts-ignore
                        name: '单系统平板sn列表',
                        // @ts-ignore
                        icon: 'setting',
                        data: {role: [UserType.SUPER_ADMIN, UserType.PAD_SYSTEM_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: TabletManageSingleIndexComponent,
                        hidden: true
                    },
                ]
            },
            {
                path: 'teachers',
                name: '教师管理',
                data: {role: [UserType.SCHOOL_ADMIN]},
                icon: 'usergroup-add',
                canActivate: [AdminAuthGuard],
                // component: StorybookIndexComponent,
                children: [
                    {path: '', redirectTo: 'index', pathMatch: 'full'},
                    {
                        path: 'index',
                        // @ts-ignore

                        icon: 'usergroup-add',
                        data: {role: [UserType.SCHOOL_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: TeachersIndexComponent,
                        hidden: true
                    },
                    {
                        path: 'add',
                        // @ts-ignore
                        name: '添加教师',
                        icon: 'plus',
                        data: {role: [UserType.SCHOOL_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: TeachersAddComponent,
                        hidden: true
                    },
                    {
                        path: 'edit/:id',
                        // @ts-ignore
                        name: '编辑教师',
                        icon: 'plus',
                        data: {role: [UserType.SCHOOL_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: TeachersAddComponent,
                        hidden: true
                    },
                ]
            },
            {
                path: 'classes',
                name: '班级管理',
                icon: 'team',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.TEACHER, UserType.SCHOOL_ADMIN]},
                // component: StorybookIndexComponent,
                children: [
                    {path: '', redirectTo: 'index', pathMatch: 'full'},
                    {
                        path: 'index',
                        // name: '班级管理',
                        // @ts-ignore
                        icon: 'team',
                        data: {role: [UserType.TEACHER, UserType.SCHOOL_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: ClassIndexComponent,
                        hidden: true
                    },
                    {
                        path: 'add',
                        // @ts-ignore
                        name: '添加班级',
                        icon: 'plus',
                        data: {role: [UserType.SCHOOL_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: ClassAddComponent,
                        hidden: true
                    },
                    {
                        path: 'edit/:id',
                        // @ts-ignore
                        name: '编辑班级',
                        icon: 'plus',
                        data: {role: [UserType.SCHOOL_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: ClassAddComponent,
                        hidden: true
                    },
                    {
                        path: ':class_id/students',
                        name: '学生管理',
                        icon: 'team',
                        canActivate: [AdminAuthGuard],
                        data: {role: [UserType.TEACHER, UserType.SCHOOL_ADMIN]},
                        hidden: true,
                        children: [
                            {path: '', redirectTo: 'index', pathMatch: 'full'},
                            {
                                path: 'index',
                                // @ts-ignore
                                icon: 'team',
                                data: {role: [UserType.TEACHER, UserType.SCHOOL_ADMIN]},
                                canActivate: [AdminAuthGuard],
                                component: StudentsIndexComponent,
                                hidden: true
                            },
                            {
                                path: 'add',
                                name: '添加学生',
                                icon: 'plus',
                                data: {role: [UserType.TEACHER, UserType.SCHOOL_ADMIN]},
                                canActivate: [AdminAuthGuard],
                                // component: StudentsAddComponent,
                                hidden: true,
                                children: [
                                    {path: '', redirectTo: 'index', pathMatch: 'full'},
                                    {
                                        path: 'index',
                                        // @ts-ignore
                                        icon: 'plus',
                                        data: {role: [UserType.TEACHER, UserType.SCHOOL_ADMIN]},
                                        canActivate: [AdminAuthGuard],
                                        component: StudentsAddComponent,
                                        hidden: true
                                    },
                                    {
                                        path: 'batch_add',
                                        // @ts-ignore
                                        name: '批量添加学生',
                                        icon: 'plus',
                                        data: {role: [UserType.TEACHER, UserType.SCHOOL_ADMIN]},
                                        canActivate: [AdminAuthGuard],
                                        component: StudentsBatchAddComponent,
                                        hidden: true
                                    },
                                ]
                            },
                            {
                                path: 'edit/:id',
                                // @ts-ignore
                                name: '编辑学生',
                                icon: 'plus',
                                data: {role: [UserType.TEACHER, UserType.SCHOOL_ADMIN]},
                                canActivate: [AdminAuthGuard],
                                component: StudentsAddComponent,
                                hidden: true
                            },
                        ]
                    },

                    {
                        path: ':class_id/statistics',
                        // @ts-ignore
                        name: '学情统计',
                        canActivate: [AdminAuthGuard],
                        data: {role: [UserType.TEACHER, UserType.SCHOOL_ADMIN]},
                        component: ClassStatisticsComponent,
                        hidden: true
                    },
                ]
            },
            {
                path: 'books',
                // @ts-ignore
                name: '查看绘本',
                icon: 'team',
                // canActivate: [AdminAuthGuard],
                data: {role: [UserType.TEACHER, UserType.SCHOOL_ADMIN]},
                redirectTo: '/user/books', pathMatch: 'full',
                // hidden: true
            },

            {
                path: 'teachingmaterial',
                name: '自制教材',
                icon: 'read',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                // redirectTo: '/user/teachingmaterial', pathMatch: 'full',
                children: [
                    {path: '', redirectTo: 'index', pathMatch: 'full'},
                    {
                        path: 'index',
                        data: {role: [UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        component: TeachingIndexComponent,
                        // @ts-ignore
                        hidden: true
                    }
                ]
                // hidden: true
            },
            {
                path: 'classes/:class_id/statistics/monthly',
                // @ts-ignore
                name: '班级月度统计信息',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.TEACHER, UserType.SCHOOL_ADMIN]},
                component: ClassStatisticsMonthlyComponent,
                hidden: true
            },
            {
                path: 'classes/:class_id/statistics/quarter',
                // @ts-ignore
                name: '班级季度统计信息',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.TEACHER, UserType.SCHOOL_ADMIN]},
                component: ClassStatisticsQuarterComponent,
                hidden: true
            },
            {
                path: 'classes/:class_id/user/:user_id/statistics',
                // @ts-ignore
                name: '学员统计详情',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.TEACHER, UserType.SCHOOL_ADMIN]},
                component: ClassStatisticsDetailComponent,
                hidden: true
            },

            {
                path: 'qupeiyin',
                name: '趣配音',
                icon: 'smile',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.SCHOOL_ADMIN, UserType.TEACHER]},
                // component: AdminUserSettingComponent,
                children: [
                    {path: '', redirectTo: 'index', pathMatch: 'full'},
                    {
                        path: 'index',
                        // @ts-ignore
                        name: '趣配音列表',
                        icon: 'smile',
                        data: {role: [UserType.SCHOOL_ADMIN, UserType.TEACHER]},
                        canActivate: [AdminAuthGuard],
                        component: QupeiyinIndexComponent,
                        hidden: false
                    },
                    {
                        path: 'form',
                        // @ts-ignore
                        name: '添加趣配音',
                        icon: 'smile',
                        data: {role: [UserType.SCHOOL_ADMIN, UserType.TEACHER]},
                        canActivate: [AdminAuthGuard],
                        // canDeactivate: [ ConfirmDeactivateGuard ],
                        component: QupeiyinFormComponent,
                    },
                ]

            },
            {
                path: 'setting',
                name: '个人中心',
                icon: 'user',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.TEACHER, UserType.SCHOOL_ADMIN]},
                // component: AdminUserSettingComponent,
                children: [
                    {path: '', redirectTo: 'index', pathMatch: 'full'},
                    {
                        path: 'index',
                        // name: '教师管理',
                        // @ts-ignore
                        icon: 'user',
                        data: {role: [UserType.TEACHER, UserType.SCHOOL_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: AdminUserSettingComponent,
                        hidden: true
                    }]

            },
            {
                path: 'account',
                name: '费用中心',
                data: {role: [UserType.SCHOOL_ADMIN], /*type: [SCHOOL_TYPE.FRANCHISED],*/ breadcrumb: '我的账户'},
                icon: 'money-collect',
                canActivate: [AdminAuthGuard],
                // component: StorybookIndexComponent,
                // hidden: true,

                children: [
                    {path: '', redirectTo: 'index', pathMatch: 'full'},
                    {
                        path: 'index',
                        // @ts-ignore
                        icon: 'profile',
                        data: {role: [UserType.SCHOOL_ADMIN], breadcrumb: '我的账户'},
                        canActivate: [AdminAuthGuard],
                        component: OrdersIndexComponent,
                        // loadChildren: () => import('./components/admin/welcome/welcome.module').then(m => m.AdminModule),
                        hidden: true
                    },
                    {
                        path: 'buy',
                        // @ts-ignore
                        icon: 'pay-circle',
                        data: {role: [UserType.SCHOOL_ADMIN], breadcrumb: '购买名额'},
                        canActivate: [AdminAuthGuard],
                        component: OrdersBuyComponent,
                        // loadChildren: () => import('./components/admin/welcome/welcome.module').then(m => m.AdminModule),
                        hidden: true
                    },
                ]
            },
            {
                path: 'gifts',
                name: '奖品管理',
                data: {role: [UserType.SCHOOL_ADMIN], /*type: [SCHOOL_TYPE.FRANCHISED],*/ breadcrumb: '奖品管理'},
                icon: 'gift',
                canActivate: [AdminAuthGuard],
                // component: StorybookIndexComponent,
                // hidden: true,

                children: [
                    {path: '', redirectTo: 'index', pathMatch: 'full'},
                    {
                        path: 'index',
                        // @ts-ignore
                        icon: 'profile',
                        data: {role: [UserType.SCHOOL_ADMIN], breadcrumb: '奖品列表'},
                        canActivate: [AdminAuthGuard],
                        component: GiftsIndexComponent,
                        // loadChildren: () => import('./components/admin/welcome/welcome.module').then(m => m.AdminModule),
                        hidden: true
                    },
                    {
                        path: 'add',
                        // @ts-ignore
                        icon: 'pay-circle',
                        data: {role: [UserType.SCHOOL_ADMIN], breadcrumb: '添加奖品'},
                        canActivate: [AdminAuthGuard],
                        component: GiftsAddComponent,
                        // loadChildren: () => import('./components/admin/welcome/welcome.module').then(m => m.AdminModule),
                        hidden: true
                    },
                    {
                        path: 'edit/:id',
                        // @ts-ignore
                        icon: 'pay-circle',
                        data: {role: [UserType.SCHOOL_ADMIN], breadcrumb: '编辑奖品'},
                        canActivate: [AdminAuthGuard],
                        component: GiftsAddComponent,
                        // loadChildren: () => import('./components/admin/welcome/welcome.module').then(m => m.AdminModule),
                        hidden: true
                    },
                ]
            },
            {
                path: 'promotions',
                name: '活动管理',
                data: {role: [UserType.SUPER_ADMIN], breadcrumb: '活动管理'},
                icon: 'fire',
                canActivate: [AdminAuthGuard],
                // component: StorybookIndexComponent,
                // hidden: true,

                children: [
                    {path: '', redirectTo: 'index', pathMatch: 'full'},
                    {
                        path: 'index',
                        // name: '我的账户',
                        // @ts-ignore
                        icon: 'profile',
                        data: {role: [UserType.SCHOOL_ADMIN], breadcrumb: '活动列表'},
                        canActivate: [AdminAuthGuard],
                        component: PromotionsIndexComponent,
                        // loadChildren: () => import('./components/admin/welcome/welcome.module').then(m => m.AdminModule),
                        hidden: true
                    },
                    {
                        path: 'add',
                        // name: '我的账户',
                        // @ts-ignore
                        icon: 'pay-circle',
                        data: {role: [UserType.SCHOOL_ADMIN], breadcrumb: '添加活动'},
                        canActivate: [AdminAuthGuard],
                        component: PromotionsAddComponent,
                        // loadChildren: () => import('./components/admin/welcome/welcome.module').then(m => m.AdminModule),
                        hidden: true
                    },
                    {
                        path: 'edit/:id',
                        // name: '我的账户',
                        // @ts-ignore
                        icon: 'pay-circle',
                        data: {role: [UserType.SCHOOL_ADMIN], breadcrumb: '编辑活动'},
                        canActivate: [AdminAuthGuard],
                        component: PromotionsAddComponent,
                        // loadChildren: () => import('./components/admin/welcome/welcome.module').then(m => m.AdminModule),
                        hidden: true
                    },


                ]
            },
            {
                path: 'trash',
                // @ts-ignore
                name: '回收站',
                icon: 'delete',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.SCHOOL_ADMIN, UserType.SUPER_ADMIN]},
                // component: AdminUserSettingComponent,
                children: [
                    {path: '', redirectTo: 'index', pathMatch: 'full'},
                    {
                        path: 'students-manager',
                        // @ts-ignore
                        name: '学员花名册',
                        icon: 'file-search',
                        canActivate: [AdminAuthGuard],
                        data: {role: [UserType.SCHOOL_ADMIN, UserType.SUPER_ADMIN]},
                        component: StudentsSuperManageIndexComponent
                    },
                ]
            },
            {
                path: 'audit',
                name: '行为审计',
                icon: 'area-chart',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.SUPER_ADMIN]},
                // component: AdminUserSettingComponent,
                children: [
                    {path: '', redirectTo: 'index', pathMatch: 'full'},
                    {
                        path: 'index',
                        // @ts-ignore
                        name: '用户行为查询',
                        icon: 'area-chart',
                        data: {role: environment.production ? [UserType.SUPER_ADMIN] : [UserType.SUPER_ADMIN, UserType.TEACHER]},
                        canActivate: [AdminAuthGuard],
                        component: UserAuditIndexComponent,
                        hidden: false
                    }
                ]
            },
            {
                path: 'log-manage',
                name: '日志管理',
                icon: 'area-chart',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.SUPER_ADMIN]},
                // component: AdminUserSettingComponent,
                children: [
                    {path: '', redirectTo: 'app', pathMatch: 'full'},
                    {
                        path: 'app',
                        // @ts-ignore
                        name: 'app日志',
                        icon: 'file-search',
                        data: {role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: AppLogComponent,
                    },
                ]

            },
            {
                path: 'lesson-resource',
                name: '教学资源',
                icon: 'edit',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.TEACHER, UserType.STUDENT, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                // component: AdminUserSettingComponent,
                children: [
                    {path: '', redirectTo: 'audio/theme', pathMatch: 'full'},
                    {path: 'audio', redirectTo: 'audio/theme', pathMatch: 'full'},
                    {path: 'video', redirectTo: 'video/theme', pathMatch: 'full'},
                    {
                        path: 'audio/theme',
                        // @ts-ignore
                        name: '音频资源',
                        icon: 'audio',
                        data: {role: [UserType.TEACHER, UserType.STUDENT, UserType.SCHOOL_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: LessonResourceAudioThemeComponent,
                    },
                    {
                        path: 'video/theme',
                        // @ts-ignore
                        name: '视频资源',
                        icon: 'video',
                        data: {role: [UserType.TEACHER, UserType.STUDENT, UserType.SCHOOL_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: LessonResourceVideoThemeComponent,
                    },
                    {
                        path: 'video-show',
                        // @ts-ignore
                        name: '视频秀资源',
                        icon: 'video',
                        data: {role: [UserType.TEACHER, UserType.STUDENT, UserType.SCHOOL_ADMIN, UserType.TEACH_RESEARCHER]},
                        canActivate: [AdminAuthGuard],
                        component: VideoShowComponent,
                    },
                    {
                        path: 'audio/theme/:theme_id',
                        // @ts-ignore
                        name: '音频资源',
                        icon: 'audio',
                        data: {role: [UserType.TEACHER, UserType.STUDENT, UserType.SCHOOL_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: LessonResourceAudioThemeComponent,
                        hidden: true
                    },
                    {
                        path: 'video/theme/:theme_id',
                        // @ts-ignore
                        name: '视频资源',
                        icon: 'video',
                        data: {role: [UserType.TEACHER, UserType.STUDENT, UserType.SCHOOL_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: LessonResourceVideoThemeComponent,
                        hidden: true
                    },
                ]

            },
            {
                path: 'pad',
                name: 'pad设置',
                icon: 'edit',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.SUPER_ADMIN]},
                // component: AdminUserSettingComponent,
                children: [
                    {path: '', redirectTo: 'video/categories', pathMatch: 'full'},
                    {
                        path: 'video/categories',
                        // @ts-ignore
                        name: '视频管理',
                        icon: 'video',
                        data: {role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: VideoCategoriesComponent,
                    },
                    {
                        path: 'video/category/:id',
                        // @ts-ignore
                        name: '视频列表',
                        icon: 'video',
                        hidden: true,
                        data: {role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: PadVideoListComponent,
                    },
                ]

            },
            {
                path: 'elp-speaker',
                name: '小象音箱管理',
                icon: 'setting',
                canActivate: [AdminAuthGuard],
                data: {role: [UserType.SUPER_ADMIN]},
                // component: StorybookIndexComponent,
                children: [
                    {path: '', redirectTo: 'index', pathMatch: 'full'},
                    {
                        path: 'firmware/index',
                        // @ts-ignore
                        name: '固件管理',
                        // @ts-ignore
                        icon: 'team',
                        data: {role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: ElpFirmwareIndexComponent,
                        hidden: false
                    },
                    {
                        path: 'firmware/add',
                        // @ts-ignore
                        name: '添加固件',
                        icon: 'plus',
                        data: {role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: ElpFirmwareAddComponent,
                        hidden: true
                    },
                    {
                        path: 'edit/:id',
                        // @ts-ignore
                        name: '编辑',
                        icon: 'plus',
                        data: {role: [UserType.SCHOOL_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: ElpFirmwareAddComponent,
                        hidden: true
                    },
                    {
                        path: 'log/index',
                        // @ts-ignore
                        name: '日志管理',
                        // @ts-ignore
                        icon: 'team',
                        data: {role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: ElpLogIndexComponent,
                        hidden: false
                    },
                    {
                        path: 'log/trace',
                        // @ts-ignore
                        name: '日志采集追踪',
                        // @ts-ignore
                        icon: 'team',
                        data: {role: [UserType.SUPER_ADMIN]},
                        canActivate: [AdminAuthGuard],
                        component: ElpTraceIndexComponent,
                        hidden: false
                    },
                ]
            },
            {
                path: 'enrollment',
                // @ts-ignore
                name: '招生引流',
                data: {role: [UserType.SCHOOL_ADMIN]},
                icon: 'usergroup-add',
                canActivate: [AdminAuthGuard],
                component: EnrollmentComponent
            },
        ]
    },

    {path: '404', component: NotFoundComponent},
    {path: '**', redirectTo: '/404'}
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {useHash: true})
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
