import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { UserService } from './user.service';
import { Router } from '@angular/router';





@Injectable({
  providedIn: 'root'
})
export class MediaV2Service extends BaseService {
  constructor(protected override http: HttpClient,  private userService: UserService, protected override router: Router) {
    super(http, router);
  }

  list(resourceType: string, query: any) {
    const queryStr = this.objectToQueryString(query);
    const url = `/media/${resourceType}/list?${queryStr}`;
    return super.get(url);
  }

  fetch(resourceType: string, id: number) {
    return super.get(`/media/${resourceType}/item/${id}`);
  }

  save(resourceType: string, data: any) {
    return super.post( `/media/${resourceType}/item/save`, data);
  }

  batchSave(resourceType: string, data: any) {
    return super.post( `/media/${resourceType}/items/save`, data);
  }


}
