import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { UserService } from '../../../../services/user.service';
import * as _ from 'lodash';
import { SyllabusService } from '../../../../services/syllabus.service';
import { CoursewareService } from '../../../../services/courseware.service';
import { MediaV2Service } from '../../../../services/media-v2.service';
import { ExerciseService } from '../../../../services/exercise.service';
import { FormArray, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { parseInt } from 'lodash';




@Component({
  selector: 'app-admin-resource-tags-rel',
  template: `
    <div>
<!--    (ngSubmit)="submitForm($event, form.value )"-->
    <form nz-form #formElement="ngForm" [formGroup]="formGroup">
    <div class="w-full h-full">
      <ng-container formArrayName="tagsGroupArray"
                    *ngFor="let tagGroupForm of tagsGroupArray.controls; let i = index;">
        <div [formGroupName]="i" style="background: #EEF5FF;" class="p-4 rounded-xl my-4 relative">
                <span nz-icon nzType="close-circle" nzTheme="fill"
                      style="z-index: 9999"
                      *ngIf="tagsGroupArray.controls.length > 1 && tagGroupForm.controls['tag_group_id.1'] == undefined"
                      (click)="removeTagsGroup(i)"
                      class="absolute text-lg right-2 top-0 cursor-pointer"></span>
          <div nz-row nzGutter="36" [hidden]="parseInt(tagGroupForm.controls.syllabus_id.value) === 0" >
            <div nz-col nzSpan="12" >
              <nz-form-item>
                <nz-form-label [nzSpan]="24" nzFor="syllabus_id" nzRequired>选择分类</nz-form-label>
                <nz-form-control [nzSpan]="24" nzErrorTip="请选择分类">
                  <nz-select nzSize="large" nzAllowClear
                             (ngModelChange)="changeSyllabus(i, $event)"
                             [formControlName]="'syllabus_id'" nzPlaceHolder="选择分类">
                    <nz-option [nzValue]="+item['id']" [nzLabel]="item['name']" *ngFor="let item of bookAttrs.syllabus"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item >
            </div>
            <!--                  <input *ngIf="!tagGroupForm.controls.syllabus_id.value" hidden nz-input formControlName="syllabus_id" value="0">-->
            <div nz-col nzSpan="12"  [hidden]="parseInt(tagGroupForm.controls.course_id.value) === 0">
              <nz-form-item>
                <nz-form-label [nzSpan]="24" nzFor="course_id" nzRequired>选择大纲</nz-form-label>
                <nz-form-control [nzSpan]="24" nzErrorTip="请选择大纲">
                  <nz-select nzSize="large" nzAllowClear
                             (ngModelChange)="changeCourse(i, $event)"
                             [formControlName]="'course_id'"
                             nzPlaceHolder="选择大纲">
                    <nz-option [nzValue]="+item['id']" [nzLabel]="item['name']"
                               [nzDisabled]="isSelectedSubOption(item)"
                               *ngFor="let item of currentSubOptions(i)"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item >
            </div>
            <!--                  <input *ngIf="!tagGroupForm.controls.course_id.value" hidden nz-input formControlName="course_id" value="0">-->
          </div>
          <div nz-row nzGutter="36" >
            <div  nz-col nzSpan="12"
                  *ngFor="let syllabusTag of (rootThemesKeyValueItems[tagGroupForm.controls['syllabus_id'].value] || []); let i = index">
              <nz-form-item >
                <nz-form-label [nzSpan]="24" [nzFor]="'tag_group_id.'+syllabusTag.id">选择{{syllabusTag.name}}</nz-form-label>
                <nz-form-control [nzSpan]="24"  [nzErrorTip]="'请选择'+syllabusTag.name">
                  <nz-select nzSize="large" nzAllowClear
                             [formControlName]="'tag_group_id.' + syllabusTag.id"
                             [nzPlaceHolder]="syllabusTag.name" nzMode="multiple">
                    <nz-option [nzValue]="item['id']"
                               [nzLabel]="item['name']"
                               *ngFor="let item of syllabusTag.children"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item >
            </div>
          </div>
          <div nz-row nzGutter="36">

            <div  nz-col nzSpan="12"
                  *ngFor="let themeControl of (rootThemesKeyValueItems[tagGroupForm.controls['course_id'].value]); let i = index">
              <nz-form-item >
                <nz-form-label [nzSpan]="24" [nzFor]="'tag_id.'+themeControl.id">选择{{themeControl.name}}</nz-form-label>
                <nz-form-control [nzSpan]="24"  [nzErrorTip]="'请选择'+themeControl.name">
                  <!--                        <div *ngFor="let item of themeControl.children">{{item['id']}}|{{item['name']}}</div>-->
                  <nz-select nzSize="large" nzAllowClear
                             [formControlName]="'tag_id.' + themeControl.id"
                             [nzPlaceHolder]="themeControl.name" nzMode="multiple">
                    <nz-option [nzValue]="item['id']"
                               [nzLabel]="item['name']"
                               
                               *ngFor="let item of themeControl.children"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item >
            </div>

          </div>

        </div>
      </ng-container>
    </div>
    <div class="text-right my-4">
      <a nz-button nzBlock [nzType]="'text'" class="mr-4"
         (click)="addTagsGroup({syllabus_id: -1, course_id: -1})"
         style="color: #40a9ff;border-color: #40a9ff;"
         [nzSize]="'default'"  nzGhost>
        添加归属分类
      </a>
    </div>
    </form>
    </div>
  `,

  styles: [
    `

    `
  ]
})
export class AdminResourceItemTagsRelComponent implements OnInit {
  userInfo: any;
  @Input() resourceType!: string;
  @Input() item_id = 0;
  @Output() tagsChange: any = new EventEmitter();
  @Input() tagsGroupArray!: FormArray;
  @Input() formGroup!: FormGroup;

  isLoading = false;
  flatSyllabusList: any[] = [];
  rootThemesKeyValueItems: any = {};
  subThemesKeyValueItems: any = {};
  syllabusKeyValueItems: any = {};

  // form!: UntypedFormGroup;

  bookAttrs: any = {};
  relTags: any[] = [];

  currentSyllabusId = 0;
  currentCourseId = 0;

  itemService!: CoursewareService|MediaV2Service|ExerciseService;

  constructor( private activatedRoute: ActivatedRoute ,
               private syllabusService: SyllabusService,
               private coursewareService: CoursewareService,
               private mediaService: MediaV2Service,
               private exerciseService: ExerciseService,
               private router: Router ,
               private fb: UntypedFormBuilder,
               private nzMessageService: NzMessageService,
               private userService: UserService) {
    this.userInfo = this.userService.getUserInfo();

    // @ts-ignore
    this.params = (new URLSearchParams(this.activatedRoute.routeConfig.params)).toString();
    // this.activatedRoute.title.subscribe({
    //   next: (title) => {
    //   }
    // });
    // this.form = this.fb.group({
    //   // id: [ this.item_id ],
    //   // school_id: [ this.userInfo.school_id || 0 ],
    //   // name: [ '' , [ Validators.required ]],
    //   // cover: [ ''],
    //   // duration: [ '00:00'],
    //   // size: [ '0'],
    //   // url: [ '', (['audio', 'video'].includes(this.bisType) ? [Validators.required] : null) ],
    //   tagsGroupArray: this.fb.array([])
    // });
    // @ts-ignore
    window.bbb = this;
  }

  ngOnInit() {
    if (this.resourceType === 'video' || this.resourceType === 'audio') {
      this.itemService = this.mediaService;
    } else if (this.resourceType === 'exercise') {
      this.itemService = this.exerciseService;
    } else {
      this.itemService = this.coursewareService;
    }
    this.isLoading = true;
    // this.coursewareService.bookAttrs(this.bisType).then((bookAttrs) => {
    this.syllabusService.category(this.resourceType).then((bookAttrs) => {
      // console.log('22222222', bookAttrs);
      const _themesKeyValueItems  = _.groupBy(bookAttrs.themes, 'syllabus_id');
      this.flatSyllabusList = this.syllabusService.flattenTree(bookAttrs.syllabus);
      this.flatSyllabusList.forEach((syllabus: any) => {
        if (typeof syllabus.path === 'string') {
          const pathParts = syllabus.path.split('/');
          syllabus.path_parts = [ ...pathParts.filter((p: string) => p.trim()).map((path: any) => +path), syllabus.id];
        }
      });

      this.rootThemesKeyValueItems = _.groupBy(bookAttrs.themes, 'syllabus_id');
      this.subThemesKeyValueItems = _themesKeyValueItems;

      this.syllabusKeyValueItems = _.keyBy(this.flatSyllabusList, 'id');

      const tagsGroupItems = _.groupBy(bookAttrs.themes, 'syllabus_id');
      console.log('tag group ', tagsGroupItems);
      this.bookAttrs = bookAttrs;
      if (this.item_id) {
        this.itemService.fetch(this.resourceType, this.item_id).then((result) => {
          console.log('itemService.fetch', result);

          this.relTags = result.themes;

          let fieldIndex = 0;
          // result.syllabus
          this.relTags.forEach((tag: any, i: number) => {
            const tg = this.syllabusKeyValueItems[tag.syllabus_id];
            if (+tag.syllabus_id && !tg) {
              return;
            }
            // const tg = this.findParentTag(bookAttrs.themes, tagGroup.syllabus_id);
            const syllabus_id = tg?.path_parts?.length > 0 ? tg?.path_parts[0] : 0;
            const course_id = tg?.path_parts?.length > 1 ? tg?.path_parts[1] : 0;
            this.addTagsGroup({syllabus_id, course_id} );
            this.changeSyllabus(i, syllabus_id);
            this.changeCourse(i, course_id);
            fieldIndex++;

            // syllabusChildren
            // const groupArray = this.form.controls['tagsGroupArray'] as FormArray;
            // const group = groupArray.at(groupArray.length - 1) as FormGroup;
            // tagGroup.tags.forEach((v: any) => {
            //   this.addTagsField({id: v.tag_group_id , value: v.tag_id, prefix: 'tag_group_id', parent: group});
            // });
          });




          this.isLoading = false;
        }).catch(err => {
          throw err;
        });
      } else {
        this.isLoading = false;
      }

    }).catch(err => {
      this.isLoading = false;
      this.nzMessageService.error(err.message || 'System Error');
    });
  }

  isSelectedSubOption(item: any) {
    const existItems = this.tagsGroupArray.controls.filter((t: any) => {
      return +item.id === +t.controls['course_id'].value;
    });
    return existItems.length > 0;
  }
  currentSubOptions (group_index: number) {
    const group = (this.tagsGroupArray.controls[group_index] as FormGroup);
    const syllabus_id = group.controls['syllabus_id'];
    // const a = this.rootThemesKeyValueItems[syllabus_id.value];
    // return a?.children;
    const item = this.bookAttrs.syllabus.find((s: any) => {
      return +s.id === +syllabus_id.value;
    });
    return  item?.children;
  }

  // get tagsGroupArray () {
  //   return (this.form.get('tagsGroupArray') || []) as FormArray;
  // }

  addTagsGroup({syllabus_id = 0, course_id = 0}: {syllabus_id?: number, course_id?: number, tags?: any[]}) {
    console.log('addTagsGroup1');
    const tagFb = this.fb.group({
      syllabus_id: this.fb.control(+syllabus_id, Validators.required),
      course_id: this.fb.control(+course_id, Validators.required),
    });
    this.tagsGroupArray.push(tagFb);

  }
  removeTagsGroup(index: number): void {
    console.log('removeTagsGroup', index);
    this.tagsGroupArray.removeAt(index);
  }
  clearTagsField({prefix = '', parent}: {prefix?: string, parent: FormGroup}) {
    // if (!parent) {
    //   parent = this.form;
    // }
    const keys = Object.keys(parent.controls);
    for (let i = 0; i < keys.length; i++) {
      if (keys[i].startsWith(`${prefix}.`)) {
        parent.removeControl(keys[i]);
      }
    }
  }
  addTagsField({id, value = null, prefix = '', parent}: {id: any, value?: any, prefix?: string,  parent: FormGroup}): void {
    // if (!parent) {
    //   parent = this.form;
    // }
    const tagsControl = [];
    parent.addControl(
      `${prefix}.` + id,
      // new UntypedFormControl(null, Validators.required)
      new UntypedFormControl(value)
    );
  }

  changeSyllabus(group_index: number, syllabus_id: number) {
    console.log('changeSyllabus', group_index, syllabus_id);
    // if (!(+syllabus_id)) {
    //   return;
    // }
    const group = (this.tagsGroupArray.controls[group_index] as FormGroup);
    group.controls['course_id'].reset(-1);
    this.clearTagsField({prefix: 'tag_group_id', parent: group});
    // this.form.controls['course_id'].setValue('');
    if (typeof syllabus_id === 'undefined') {
      this.currentSyllabusId = 0;
      this.currentCourseId = 0;
      return;
    }

    this.currentSyllabusId = syllabus_id;
    const selectedTags = this.rootThemesKeyValueItems[syllabus_id] || [];
    const tagGroupItem = this.relTags.find(tag => +tag.syllabus_id === +syllabus_id);
    const tags = this.bookAttrs.themes.filter((tag: any) => +tag.syllabus_id === +syllabus_id);
    tags.forEach((tag: any) => {
      // const value = tag.children.map((v: any) => v.id);
      const tagItem = tagGroupItem?.tags.find((s: any) => {
        return s.tag_group_id === +tag.id;
      });
      this.addTagsField({
        id: tag.id,
        value: tagItem?.tag_id ,
        // value: tagGroupItem?.tag_id ,
        prefix: 'tag_group_id',
        parent: group});
    });
  }

  changeCourse(group_index: number, course_id: number) {
    console.log('changeCourse', group_index, course_id);

    if (!(+course_id) || !course_id) {
      return;
    }
    const group = (this.tagsGroupArray.controls[group_index] as FormGroup);
    group.controls['course_id'].setValue(+course_id, {emitViewToModelChange: false});
    // group.controls['course_id'].reset(+course_id);
    this.clearTagsField( {prefix: 'tag_id', parent: group});
    if (!course_id) {
      this.currentCourseId = 0;
      return;
    }
    // const item = this.bookAttrs.themes.filter((s: any) => {
    //   return +s.syllabus_id === +syllabus_id;
    // });
    this.currentCourseId = course_id;
    const selectedTags = this.subThemesKeyValueItems[course_id] || [];
    const tagGroupItem = this.relTags.find(t => +t.syllabus_id === +course_id);
    const tags = this.bookAttrs.themes.filter((tag: any) => +tag.syllabus_id === +course_id);
    tags.forEach((tag: any) => {
      const value = tag.children.map((v: any) => v.id);
      const tagItem = tagGroupItem?.tags.find((s: any) => {
        return s.tag_group_id === +tag.id;
      });
      this.addTagsField({
        value: tagItem?.tag_id,
        id: tag.id,
        prefix: 'tag_id',
        parent: group});
    });
    // this.tagsChange.emit(this.form.value);
  }

  protected readonly parseInt = parseInt;
}
