import { Component, ContentChildren, ElementRef, EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { NzTableQueryParams } from 'ng-zorro-antd/table/src/table.types';




@Component({
  selector: 'tw-table',
  template: `
    
    <div class="page-main-table">
      <div class="table-header"></div>
    <ng-template #rangeTemplate let-range="range" let-total>
      显示 {{ total }} 条资料中的 {{ range[0] }} 到 {{ range[1] }} 条数据
    </ng-template>
      
    <nz-table
      [nzTotal]='nzTotal'
      [nzPageIndex]="nzPageIndex"
      [nzPageSize]="nzPageSize||10"
      [nzFrontPagination]="false"
      [nzShowTotal]="rangeTemplate"
      [nzShowPagination]="true"
      (nzQueryParams)="queryParams($event)"
      [nzData]="nzData"
      [nzLoading]="nzLoading"
    >
      <ng-content></ng-content>
    </nz-table>
<!--      <nz-pagination [nzPageIndex]="nzPageIndex" [nzTotal]="nzTotal" [nzPageSize]="nzPageSize" [nzShowTotal]="rangeTemplate"></nz-pagination>-->
    </div>
  `,
  styles: [
    `
     
    `
  ],
  // standalone: true
})
export class CommonTwTableComponent {
  @Input() nzTotal!: number;
  @Input() nzPageIndex!: number;
  @Input() nzPageSize!: number;
  @Input() nzData!: any[];
  @Input() nzLoading!: boolean;
  @Output() nzQueryParams: EventEmitter<NzTableQueryParams> = new EventEmitter();

  queryParams(params: NzTableQueryParams): any {
    this.nzQueryParams.emit(params);
  }
  get data() {
    return this.nzData;
  }
}
