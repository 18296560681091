import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SchoolService } from '../../../../../services/school.service';
import { ClassService } from '../../../../../services/class.service';
import { UserStatus, UserType } from '../../../../../model/Const';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators, FormArray, UntypedFormControl } from '@angular/forms';
import { CoursewareService } from '../../../../../services/courseware.service';
import { TwNoticeService } from '../../../../../services/tw-notice.service';
import { HWResourceService } from '../../../../../services/huawei-resource.service';
import * as _ from 'lodash';
import { SyllabusService } from '../../../../../services/syllabus.service';
import { MediaV2Service } from '../../../../../services/media-v2.service';
import { TagsService } from '../../../../../services/tags.service';
import { parseInt } from 'lodash';
import { ExerciseService } from '../../../../../services/exercise.service';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { HttpHeaders } from '@angular/common/http';
import { from, Subscription } from 'rxjs';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-admin-resource-batch-add',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class AdminResourceItemBatchAddComponent implements OnInit {
  user: any = null;
  constUserType = UserType;
  currentSyllabusId = 0;
  currentCourseId = 0;

  isLoading = false;
  form!: UntypedFormGroup;

  syllabusList: { syllabus_id: number, course_id: number}[] = [];


  item_id = 0;
  bookAttrs: any = {};
  syllabusChildren = [];
  syllabusKeyValueItems: any = {};

  rootThemesKeyValueItems: any = {};
  subThemesKeyValueItems: any = {};

  relTags: any[] = [];
  bookInfo: any = null;
  flatSyllabusList: any[] = [];
  batchUploadFileList: NzUploadFile[] = [];

  subscription: Subscription = new Subscription();

  bisType = 'cartoon';
  tabIndex = 0;
  items_status: any = {};
  // readingStatus = false;
  // classroomReadingStatus = false;

  host = '';
  protocol = '';
  bucket = '';
  exercise_items = [];

  // itemService!: CoursewareService|MediaV2Service|ExerciseService;
  itemService!: MediaV2Service;


  formatter = new Intl.NumberFormat('en', {
    minimumIntegerDigits: 2,
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
    useGrouping: false,
  });

  constructor(private userService: UserService,
              private clsService: ClassService,
              private syllabusService: SyllabusService,
              private tagsService: TagsService,
              private coursewareService: CoursewareService,
              private mediaService: MediaV2Service,
              private exerciseService: ExerciseService,
              private activatedRoute: ActivatedRoute ,
              private twNoticeService: TwNoticeService ,
              private router: Router ,
              private fb: UntypedFormBuilder,
              private uploader: HWResourceService,
              private nzMessageService: NzMessageService) {
    this.user = this.userService.getUserInfo();
    const u = new URL(window.location.href);
    this.protocol = u.protocol;
    this.host = HWResourceService.cdnHost;
    this.activatedRoute.title.subscribe({
      next: (title) => {
      }
    });
    console.log('[form] this.activatedRoute', this.activatedRoute.pathFromRoot[2].data);
    this.bisType = this.activatedRoute.pathFromRoot[2].snapshot.url[2].path;
    if (this.bisType === 'video' || this.bisType === 'audio') {
      this.itemService = this.mediaService;
    } else {
      this.nzMessageService.error('this.bisType error');
    }
    this.activatedRoute.queryParams.subscribe( (query: any) => {
      if (query.tabIndex && parseInt(query.tabIndex, 10) === query.tabIndex * 1) {
        this.tabIndex = parseInt(query.tabIndex, 10);
      }
    });
    // this.activatedRoute.queryParams.subscribe( (query: any) => {
    //   if (query.page && parseInt(query.page, 10) === query.page * 1) {
    //     this.currentPageIndex = parseInt(query.page, 10);
    //   }
    // });


    this.activatedRoute.params.subscribe( params => {
      console.log('params', params);
      this.item_id = +params['item_id'] || 0;
      this.form = this.fb.group({
        school_id: [ this.user.school_id || 0 ],
        tagsGroupArray: this.fb.array([]),
        itemsArray: this.fb.array([])
      });

    });
    // @ts-ignore
    window.aaa = this;
  }


  onVideoCoverUploadSuccess(evt: any, i: number) {
    // @ts-ignore
    this.itemsArray.controls[i].controls.cover.setValue(evt.url);
  }

  clearAudio($event: any) {
    console.log('clearAudio', $event);
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.form.controls['url'].setValue(null);
  }
  onAudioUploadFailure(evt: any) {
    console.log('onAudioUploadFailure', evt);
  }
  onAudioUploadSuccess(evt: any) {
    console.log('onAudioUploadSuccess', evt);
    if (this.bisType === 'audio') {
      this.form.controls['url'].setValue(evt.url);
      // this.form.controls['duration'].setValue(this.formatTime(evt.file.duration / 1000));
      const time = parseInt(String(evt.file.duration / 1000), 10);
      this.form.controls['duration'].setValue(time);
      this.form.controls['size'].setValue(evt.file.size);
    }
  }

  formatTime(time: number) {
    time = parseInt(String(time), 10);
    const sec = time % 60;
    const min = Math.floor(time / 60);
    const hour = Math.floor(time / (60 * 60));
    let t = `${min}:${sec}`;
    if (hour) {
      t = `${hour}:${t}`;
    }
    return t;
  }




  get tagsGroupArray() {
    return this.form.get('tagsGroupArray') as FormArray;
  }
  get itemsArray () {
    return (this.form.get('itemsArray') || []) as FormArray;
  }



  get titleName() {
    let title = '';
    if (this.bisType === 'cartoon') {
      title = '绘本';
    } else if (this.bisType === 'book') {
      title = '绘本';
    } else if (this.bisType === 'audio') {
      title = '音频';
    } else if (this.bisType === 'video') {
      title = '视频';
    } else if (this.bisType === 'exercise') {
      title = '练习';
    }
    return title;
  }
  get actionName() {
    let action = '新增';
    if (this.item_id) {
      action = '编辑';
    }
    return action;
  }
  get backUrl () {
    return `/admin/v3/resource/${this.bisType}/list`;
  }
  ngOnInit() {

  }




  tabIndexChange($evt: any) {
    console.log('tabIndexChange', $evt, this.tabIndex);
    this.tabIndex = $evt;
  }
  noticeReadingStatus() {
    this.twNoticeService.warning({nzContent: '請先上傳Reading素材'});
  }
  setCover(event: any, i: number) {
    console.log('changeCover', event, i );
    // this.form.controls['cover'].setValue(event.url);
    // this.form.controls['itemsArray'][i].controls['cover'].setValue(event.url);
  }

  submitForm = ($event: any, value: any) => {
    if ($event) {
      $event.preventDefault();
    }
    if (this.isLoading) {
      return;
    }
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsDirty();
      this.form.controls[ key ].updateValueAndValidity();
    }

    let tagValid = true;
    value.tagsGroupArray.forEach((tag: any) => {
      const {course_id, syllabus_id} = tag;
      if (course_id <= 0 || syllabus_id < 0) {
        tagValid = false;
      }
    });

    if (!tagValid || !value.tagsGroupArray || (Array.isArray(value.tagsGroupArray) && value.tagsGroupArray.length === 0)) {
      this.nzMessageService.error('请正确关联分类');
      return;
    }
    this.isLoading = true;
    console.log('submitForm', $event, value);
    this.itemService.batchSave(this.bisType, value).then((result) => {
      this.isLoading = false;
      const modal = this.twNoticeService.success({nzContent: '保存成功'});
      modal.afterClose.subscribe(() => {
        if (!this.item_id) {
          this.router.navigate([`/admin/v3/resource/${this.bisType}`]);
        }

      });
    }).catch(err => {
      this.isLoading = false;
      this.nzMessageService.error(err.message || 'System Error');
    });
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.form.reset();
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsPristine();
      this.form.controls[ key ].updateValueAndValidity();
    }
  }

  dragStart($evt: any) {
    console.log('dragStart', $evt);
  }
  dragging($evt: any) {
    console.log('dragging', $evt);
  }

  reloadData() {
    this.ngOnInit();
  }

  genUid(str: string) {
      const encoder = new TextEncoder();
      const data = encoder.encode(str);
      let binary = '';
      for (const byte of data) {
        binary += String.fromCharCode(byte);
      }
      return btoa(binary);

  }
  beforeBatchUpload = (file: NzUploadFile, fileList ?: NzUploadFile[]) => {
    console.log('beforeBatchUpload', file, fileList);
    // @ts-ignore
    file.uniqueId = this.genUid(`${file.name}|${file.lastModified}|${file.size}`);
    file.uid = this.genUid(`${file.name}|${file.lastModified}|${file.size}`);

    const existFiles1 = this.batchUploadFileList.filter((f: NzUploadFile) => {
      // @ts-ignore
      return f.uid === file.uid;
    });
    const existFiles2 = this.itemsArray.controls.filter((c) => {
      // @ts-ignore
      return file.uid === c.controls.uid.value;
    });
    if (existFiles1.length !== 0 || existFiles2.length !== 0 ) {
      this.nzMessageService.warning(`${file.name} already exists!`);
    }
    return existFiles1.length === 0 && existFiles2.length === 0;
  }

  getFileType(file: NzUploadFile): string {
    const extension = file.name.split('.').pop()?.toLowerCase();
    // @ts-ignore
    if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) { return 'image'; }
    if (extension === 'pdf') { return 'pdf'; }
    // @ts-ignore
    if (['xls', 'xlsx'].includes(extension)) { return 'excel'; }
    return 'other';
  }

  isPreviewable(file: NzUploadFile): boolean {
    const type = this.getFileType(file);
    return type === 'image' || type === 'pdf';
  }

  previewFile(file: NzUploadFile): void {
    // 实现预览逻辑
  }
  removeFile(file: NzUploadFile): void {
    console.log('removeFile',  this.batchUploadFileList);
    // 通过uid过滤掉要删除的文件
    this.batchUploadFileList = this.batchUploadFileList.filter(item => item.uid !== file.uid);
  }
  removeFileByIndex(index: number): void {
    console.log('removeFileByIndex',  index);
    this.itemsArray.removeAt(index);
  }

  customUploadRequest = (options: any): Subscription => {
    const { file, onSuccess, onError, onProgress } = options;
    console.log('customUploadRequest', file.uniqueId );

    const that = this;
    return from(this.uploader.asyncDoUpload({
      file,
      // osspath: 'debug/v1',
      osspath: '',
      progress_cb: onProgress,
      success_cb: async ({name, hash, url, file: f}) => {
        const duration = await that.getMediaFileDuration(url);
        f.duration = duration;
        onSuccess({name, hash, url, file: f, duration});
      },
      error_cb: onError}))
      .subscribe({
        next: (response) => {
          console.log('Data loaded', response);
        },
        error: (error) => {
          console.error('Error loading data', error);
        }
      });
  }

  convertTimeToTag(time: number, options: {h: boolean, ms: boolean}): string {
    if (time === undefined) {
      return '';
    }
    const r = moment.utc(time * 1000).format('HH:mm:ss');
    return r;
  }

  handleChange({ file, fileList }: NzUploadChangeParam): void {
    // console.log('handleChange', file, fileList);
    const status = file.status;
    if (status !== 'uploading') {
      console.log('handleChange', status, file, fileList);
    }
    if (status === 'done') {
      const fname = file.name || file.response.name;
      const n = fname.substr(0, fname.lastIndexOf('.'));
      const item = this.fb.group({
        // school_id: this.fb.control(this.user.school_id, Validators.required),
        cover: this.fb.control(''),
        name: this.fb.control(n, Validators.required),
        uid: this.fb.control(file.uid, Validators.required),
        // url: this.fb.control(file.url, Validators.required),
        // suffix: this.fb.control(+course_id, Validators.required),
      });
      if (file.size) {
        item.addControl('size', this.fb.control(file.size, Validators.required));
      }
      // @ts-ignore
      if (file?.response?.duration) {
        // @ts-ignore
        item.addControl('duration', this.fb.control(file?.response?.duration, Validators.required));
      }
      // @ts-ignore
      if (file?.response?.url) {
        // @ts-ignore
        item.addControl('url', this.fb.control(file?.response?.url, Validators.required));
      }

      this.itemsArray.push(item);
      this.removeFile(file);

      this.nzMessageService.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      this.nzMessageService.error(`${file.name} file upload failed.`);
    }
  }

  getMediaFileDuration(url: string) {
    console.log('getMediaFileDuration')
    return new Promise<number>(resolve => {
      const a = new Audio();
      a.preload = 'metadata';
      // @ts-ignore
      a.src = url;
      a.addEventListener('loadedmetadata', () => {
        const duration = a.duration; // the duration variable now holds the duration (in seconds) of the audio clip
        a.preload = 'none';
        a.src = '';
        a.remove();
        resolve(duration);
      });

    });

  }

  protected readonly UserStatus = UserStatus;
  protected readonly Boolean = Boolean;
  protected readonly JSON = JSON;
  protected readonly Object = Object;
  protected readonly Array = Array;
  protected readonly parseInt = parseInt;
  protected readonly Math = Math;
}
